import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as moment from 'moment';
import webServices from '../services/webServices';
import AppConstants from '../appConstants';
import whiteRose from '../assets/images/rose.svg';
import whiteFlowser from '../assets/images/lovrican-white.svg';
import whiteLike from '../assets/images/hear-white.svg';

// import download from '../assets/images/download.svg';
// import visa from '../assets/images/visa.png';
// import master from '../assets/images/maestor.png';
// import paypal from '../assets/images/paypal.png';

import { pageTitle } from '../redux/actions/home.actions';
import Spinner from '../components/general/spinner';

const BillingDetailsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [invoice, setInvoice] = useState([]);
  const [plans, setPlans] = useState([]);
  const [isSpinner, setIsSpinner] = useState(true);

  useEffect(async () => {
    dispatch(pageTitle('Billing Details'));

    const resp = await webServices.getOrderDetails();
    if (resp && resp.success) {
      if (resp.data.length > 0) {
        setInvoice(resp.data);
        setTimeout(() => {
          setIsSpinner(false);
        }, 400);
      } else {
        history.push({
          pathname: 'plans'
        });
      }
    }

    (async () => {
      const response = await webServices.getPlans();
      if (response && response.success) {
        setPlans(response.data);
      }
    })();
  }, []);

  const handleClickRedirectToPlan = (plan) => {
    history.push({
      pathname: AppConstants.routes.plans,
      state: { planDetail: plan, planDetails: location?.state?.planDetails }
    });
  };

  return (
    <>
      <div className="full-page planWrapper">
        <div className="container">
          {isSpinner ? (
            <Spinner active={isSpinner} />
          ) : (
            <>
              <div className="paymentDetailsWrapper">
                <div className="mid-container panConatiner">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-center">
                        {!!plans.length &&
                          plans.map((plan) => (
                            <div
                              key={plan.id}
                              className="top-nav"
                              onClick={() => handleClickRedirectToPlan(plan)}
                              aria-hidden="true"
                              style={{ cursor: 'pointer' }}
                            >
                              <div
                                className={`top-nav-inner ${location?.state?.planDetails?.currentPlan ===
                                  plan.name
                                  ? 'active'
                                  : ''
                                  }`}
                              >
                                <i className="fa fa-check-circle" />
                              </div>
                              <p>{plan.name}</p>
                            </div>
                          ))}
                      </div>
                      <div className="listWrapper">
                        {!!invoice.length &&
                          invoice.map((value) => <PurchaseHistoryDetailsRowComponent purchaseHistory={value} />)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const PurchaseHistoryDetailsRowComponent = ({ purchaseHistory }) => {
  const { id, planId, orderId, purchaseQuantity, createdAt: purchasedAt, amount: planAmount, payment_gateway: paymentGateway, plan: { name: planName, plan_type: planType } } = purchaseHistory;
  const planColorsArr = {
    1: 'orange',
    2: 'green',
    3: 'rose',
  };
  const planImgArr = {
    1: whiteRose,
    2: whiteLike,
  };
  const planColor = planColorsArr[planId] ?? 'rose';
  const planImage = planImgArr[planId] ?? whiteFlowser;
  let total = planAmount;
  let purchasedPlanName = planName;
  if (planType === '1') {
    purchasedPlanName = `${purchasedPlanName} ${purchaseQuantity > 1 ? 'Roses' : 'Rose'}`;
  } else if (planType === '2') { 
    purchasedPlanName = `${purchasedPlanName} ${purchaseQuantity > 1 ? 'Likes' : 'Like'}`;
  }

  if (paymentGateway === 1) {
    const paymentDetails = purchaseHistory.orderDetails
      ? JSON.parse(purchaseHistory.orderDetails)
      : '';

    total = paymentDetails && paymentDetails?.purchase_units ? (paymentDetails?.purchase_units[0]?.payments?.captures[0]?.amount?.purchaseHistory) : 0;
  }

  return (
    <>
      <div
        key={id}
        className={`card ${planColor}`}
      >
        <div className="card-header">
          <div className="icon">
            <img
              src={planImage}
              className="img-fluid"
              alt="Lovrican rose"
            />
          </div>
        </div>
        <div className="card-body">
          <div className="planName">{purchasedPlanName}</div>
          <div className="date">{moment(purchasedAt).format('MMM DD, YYYY')}</div>
          <div className="row">
            <div className="col-md-6">
              <div className="card-details">
                <p>
                  Order ID <br />
                  <span>{orderId}</span>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-details total">
                <p>
                  <span>Total $</span>
                </p>
                <div className="price">${total}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingDetailsPage;
