import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserPlansDetails } from '../../../redux/actions/user.actions';
import AppConstants from '../../../appConstants';

const PaymentSuccessPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState('');
  const { planDetails, current } = useSelector((state) => state.user);

  useEffect(() => {
    if (location?.state?.selectedPlan) {
      dispatch(getUserPlansDetails());
      const plantype = location?.state?.selectedPlan?.plan_type;
      if (plantype === '2') {
        setSelectedPlan('Like Plan');
      } else if (plantype === '1') {
        setSelectedPlan('Rose Plan');
      } else {
        setSelectedPlan(`${location?.state?.selectedPlan?.name} Plan`);
      }
    }
    else {
      history.push({
        pathname: AppConstants.routes.plans,
      });
    }
  }, [dispatch, location?.state?.selectedPlan]);

  return (
    <>
      <div
        className={`inner-wrapper-newdesign paymentStatus paymentHeight ${current.looking_for === 'WOMAN' ? 'womanSuccess' : 'manSuccess'
          }`}
      >
        <div className="row">
          <div className="col-md-12 ">
            <div className="largeScreen">
              <div className="title">
                <h1>
                  Payment <br />
                  Successful!
                </h1>
                <p className="pt-3 pb-3">
                  Congratulations, {current?.first_name}!
                </p>
                <p>
                  Your payment has been successfully processed, and you <br />
                  are now subscribed to the {selectedPlan} at Lovrican! Get{' '}
                  <br />
                  ready to embark on an incredible journey of love and <br />
                  connections with our exclusive features and premium <br />
                  matchmaking services.
                </p>
                <p>
                  With your subscription, you can now unlock a world of <br />
                  romantic possibilities. Explore curated matches and engage{' '}
                  <br />
                  in meaningful conversations with like-minded individuals.
                </p>
                <ul className="list-unstyled pt-2 d-flex">
                  <li className="mr-md-3 meetMatch">
                    <button
                      type="button"
                      className="btn addPhoto"
                      onClick={() => history.push('/match-queue')}
                    >
                      Meet Your Matches
                    </button>
                  </li>
                  <li className="patmentHistory">
                    <button
                      type="button"
                      style={{ backgroundColor: '#634a65' }}
                      className="btn addPhoto"
                      onClick={() =>
                        history.push({
                          pathname: '/billing-details',
                          state: { planDetails }
                        })
                      }
                    >
                      Payment History
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessPage;
