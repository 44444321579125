import { React } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AppConstants from '../appConstants';
import heartIcon from '../assets/images/heart.svg';

const FirstMatchPopup = ({ modalStatus, handleClose, userDetails }) => {
  const history = useHistory();

  const handleChatNowBtn = async () => {
    handleClose();
    history.push({ pathname: AppConstants.routes.chat });
  };

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel congratulation_modal"
        show={modalStatus}
        onHide={handleClose}
        centered
      // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100 text-theme-dark mt-3">
              Congratulations
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-3">
            <img
              src={heartIcon}
              className="img-rose-fluid"
              alt="Lovrican heart"
            />
          </div>
          <p className='text-center text-theme-purple text-font-playfair font-20'>First Match</p>
          <p>
            Hi {userDetails.first_name}, your first match is here, and {userDetails.gender === 'MALE' ? 'she' : 'he'}  could be the one! Start the conversation today with Lovrican’s messaging features.
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn1 mr-0 mt-2"
              onClick={() => handleChatNowBtn()}
            >
              Chat Now
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FirstMatchPopup;
