import { React, useState } from 'react';
import { Modal } from 'react-bootstrap';

const REQUEST_UPDATE_FIELDS_OBJ = {
  'profile_photos': 'Add valid photo(s)',
  'linkedin_url': 'Add your valid Linkedin Profile URL',
//   'phone_no': 'Add valid phone number',
  'origin_info': 'Add valid origin info',
  'profession_info': 'Add valid profession info',
  'about_me_info': 'Add credible about me info',
  'hobbies': 'Add up to 5 Hobbies',
  'looking_for_info': 'Add reasonable Looking for info',
  'education_info': 'Add valid education info',
  'income_info': 'Add Income that is consistent with your profession',
  'additional_comments': 'Additional Comments',
};

const ProfileUpdateRequestPopup = ({ modalStatus, handleClose, sendBtnHandler }) => {
  const [isSendBtnDisabled, setIsSendBtnDisabled] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState(Object.keys(REQUEST_UPDATE_FIELDS_OBJ).reduce((acc, curr) => {
    acc[curr] = { isChecked: false, value: null };
    return acc;
  }, {}));

  const handelCheckboxChangeEvt = (e) => {
    const { name, checked: isChecked } = e.target;

    setFormData(prevData => {
      const newFormData = { ...prevData };
      newFormData[name].isChecked = isChecked;
      return newFormData;
    });
  }

  const handelTextareaKeyUpEvt = (e) => {
    setFormData(prevData => {
      const newFormData = { ...prevData };
      newFormData.additional_comments.value = e.target.value;
      return newFormData;
    });
  }

  const handleSendBtnClick = async () => {
    let errorMsg = '';
    let isAnyFieldSelected = false;
    
    Object.keys(formData).forEach(key => {
      if (formData[key].isChecked) {
        isAnyFieldSelected = true;
      }
    });
    if (!isAnyFieldSelected) {
      errorMsg = 'Please select at least one field.';
    } else if (formData.additional_comments.isChecked && (formData.additional_comments.value === null || formData.additional_comments.value.trim() === '')) {
      errorMsg = 'Please add comments.';
    }

    setError(errorMsg);

    if (errorMsg.length === 0) {
      setIsSendBtnDisabled(true);
      await sendBtnHandler(formData);
    }
  }

  return (
    <Modal
      className="filter-model dashbaord_popup"
      show={modalStatus}
      onHide={handleClose}
      centered
    // backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="w-100">
          <h5 className="modal-title w-100">Request user to update</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.keys(REQUEST_UPDATE_FIELDS_OBJ).map(el => (
          <div className='form-group'>
            <input
              type="checkbox"
              id={`checkbox_${el}`}
              className="form-check-input"
              value={formData[el].isChecked}
              name={el}
              onChange={handelCheckboxChangeEvt}
            />
            <label
              htmlFor={`checkbox_${el}`}
              className="form-check-label"
            >
              {REQUEST_UPDATE_FIELDS_OBJ[el]}
            </label>
          </div>
        ))}
        <div className="form-group" style={{ "display": formData.additional_comments.isChecked ? 'block' : 'none' }}>
          <textarea className='form-control' name="additional_comments" placeholder='Put your comments here...' onKeyUp={handelTextareaKeyUpEvt} />
        </div>
        
        {error && (<div className='text-danger'>{error}</div>)}

      
      </Modal.Body>
      <Modal.Footer>
  
          <button
            type="button"
            className="btn btn-block themebtn1 w-100"
            onClick={handleSendBtnClick}
            disabled={isSendBtnDisabled}
          >
            SEND
          </button>
    
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileUpdateRequestPopup;
