import { React } from 'react';
import { Modal } from 'react-bootstrap';
import webServices from '../services/webServices';
import rose from '../assets/images/rose.svg';
import AppConstants from '../appConstants';

const WelcomePopup = ({ modalStatus, handleClose, userDetails }) => {
  const handleConfirm = async () => {
    handleClose(false);
    await webServices.updateUserSettings({
      readBonusMsg: '1'
      // profileLevel:
      //   userDetails.profileLevel === 7 ? userDetails.profileLevel : 0
    });
  };

  return (
    <>
      <Modal
        className="filter-model PlanStatusModel"
        show={modalStatus}
        onHide={modalStatus}
        centered
        // backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h5 className="modal-title text-center w-100">Welcome Gift</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="roseAlert d-none1">
            <img src={rose} alt="Lovrican rose" />
          </div>
          <div className="countRose">
            {process.env.REACT_APP_INIT_ROSE} Roses & {AppConstants.USER_APPROVAL_PREMIUM_PLAN_DURATION} Free
          </div>
          <p>
            Hi {`${userDetails?.first_name || ''}`.trim()}, Welcome to Lovrican. We are delighted 
            to have you on our platform and we can’t wait for you to find your special someone. 
            Here are {process.env.REACT_APP_INIT_ROSE} Roses, and {AppConstants.USER_APPROVAL_PREMIUM_PLAN_DURATION} of premium Lovrican 
            service on us (free) as a welcome gift to help you get started. 
            You can gift these roses to your favourite potential matches and show your affection in a 
            truly romantic way.
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn theme-btn themebtn2"
              onClick={() => handleConfirm()}
            >
              Continue
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default WelcomePopup;
