import { useEffect, useState } from 'react';
import React, { useDispatch } from 'react-redux';
import webServices from '../../services/webServices';
import { pageTitle } from '../../redux/actions/home.actions';
import rose from '../../assets/images/rose-yellow.svg';
import themeRose from '../../assets/images/rose-theme.svg';
import greenRose from '../../assets/images/rose-green.svg';
import Spinner from '../general/spinner';
import PayPalCheckoutPage from '../../services/PayPalCheckout';
import AuthorizeNetPayment from '../../services/AuthorizeNetPayment';

const getRoseType = (planName) => {
  if (planName === '1') return rose;
  if (planName === '5') return greenRose;
  return themeRose;
}

const RosePlansComponent = () => {
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [availableRoses, setAvailableRoses] = useState(0);
  const [isSpinner, setIsSpinner] = useState(true);

  useEffect(() => {
    (async () => {
      const response = await webServices.getRosePlans();
      if (response && response.success) {
        setPlans(response.data);
        setAvailableRoses(response?.roses?.my_roses || 0);
        setTimeout(() => {
          setIsSpinner(false);
        }, 400);
      }
    })();
    dispatch(pageTitle('Rose Plans'));
  }, []);

  useEffect(() => {
    if (!plans.length) {
      return;
    }
    setSelectedPlan(
      plans.reduce((acc, value) => (acc.price > value.price ? acc : value), {})
    );
  }, [plans]);

  const handlePlanClick = (event, plan) => {
    event.preventDefault();
    setSelectedPlan(plan);
    // setPlanDetails(plan.name);
  };

  const createPaymentPaypal = (event) => {
    event.preventDefault();
    setIsProcessing(true);
    (async () => {
      const response = await webServices.createOrder({
        planId: selectedPlan.id
      });
      if (response && response.data.result.status === 'CREATED') {
        const approveLink = response.data.result.links.find(
          (link) => link.rel === 'approve'
        );
        if (approveLink) {
          window.location.replace(approveLink.href);
        }
      }
    })();
  };

  return (
    <>
      {isSpinner ? (
        <Spinner active={isSpinner} />
      ) : (
        <>
          <div className="full-page planWrapper d-none1">
            <div className="container">

              <div className="plan-container">
                <h1 className="ext-left text-lg-center text-md-center">
                  Simple, Transparent Pricing
                </h1>
              </div>

              <div className="text-left text-lg-center text-md-center">
                <div className="availableRose">
                  <div className="w-100 d-flex justify-content-around align-items-center">
                    <img
                      src={themeRose}
                      className="img-rose-fluid"
                      alt="Lovrican rose"
                    />
                    <span>
                      <em>{availableRoses}</em>
                    </span>
                  </div>
                </div>
              </div>

              <div className="mid-container-outer">
                <div className="mid-container panConatiner roseContainer">
                  <div className="row">
                    <div className="col-md-6 d-none" />
                    <div className="col-md-12">
                      <div className="plan-details rose-plan-details">
                        <div className="nav flex-column nav-pills">
                          {!!plans.length &&
                            plans.map((plan) => <PlanRowComponent key={plan.id} planDetails={plan} selectedPlan={selectedPlan} planPerDayPrice={plans[0].price} handlePlanClick={handlePlanClick} />)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="white-border" />
                <div id="paypal-button-container" />
                <div className="mid-container mt-4">
                  <PlanPurchaseButtonComponent selectedPlan={selectedPlan} paypalPaymentClickHandler={createPaymentPaypal} isProcessing={isProcessing} />
                </div>
              </div>

            </div>
          </div>
        </>
      )}
    </>
  );
};

const PlanRowComponent = ({ planDetails, selectedPlan, handlePlanClick }) => {
  const { id: planId, name: planName, price: planPrice } = planDetails;
  const { id: selectedPlanId } = selectedPlan;

  const roseType = getRoseType(planName);

  // const planDiscount = (((planName * planPerDayPrice - planPrice) * 100) / (planName * planPerDayPrice)).toFixed(0);

  return (
    <>
      <a
        className={`nav-link ${selectedPlanId === planId ? 'active' : ''}`}
        data-toggle="pill"
        href="/"
        onClick={(event) => handlePlanClick(event, planDetails)}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="lft d-flex align-items-center">
            <div className="icon mr-3">
              <i className="fa fa-circle-thin" aria-hidden="true" />
              <i className="fa fa-check-circle" aria-hidden="true" />
            </div>
            <div className="rose-box">
              <img src={roseType} className="img-rose-fluid" alt="Lovrican rose" />
            </div>
            <div className="plan_title">
              <div className={`save d-flex align-items-center ${planName.toLowerCase()}`} >
                <h3>{planName}</h3> Roses
              </div>
            </div>
          </div>
          {/* <div className="plan-center">
            {planDiscount > 0 && (
              <div className="bottom">
                <div className={`save ${planName.toLowerCase()}`}>
                  {`Save ${planDiscount}%`}
                </div>
              </div>
            )}
          </div> */}
          <div className="rgt">
            <div className="price">${planPrice}</div>
          </div>
        </div>
      </a>
    </>
  );
}

const PlanPurchaseButtonComponent = ({ selectedPlan, paypalPaymentClickHandler, isProcessing }) => {
  const { id, price } = selectedPlan;

  return (
    <>
      <div className="mobile_plan justify-content-center text-center mobileFooter">
        <AuthorizeNetPayment
          planId={id}
          planAmount={price}
          planType='ROSE_PLAN'
          selectedPlan={selectedPlan}
        />
      </div>

      <div className="mobile_plan justify-content-center text-center mobileFooter d-none">
        <PayPalCheckoutPage
          planId={id}
          amount={price}
          selectedPlan={selectedPlan}
        />
      </div>

      <div className="d-sm-flex justify-content-center text-center mobileFooter d-none">
        <button
          onClick={paypalPaymentClickHandler}
          type="button"
          className="btn btn-info theme-btn ml-3 gr_color mb-2 mb-md-0 d-none"
          disabled={isProcessing}
        >
          Pay with PayPal
        </button>
        <button
          onClick={paypalPaymentClickHandler}
          type="button"
          className="btn btn-info theme-btn ml-3 r_color d-none"
          disabled={isProcessing}
        >
          Pay with Card
        </button>
      </div>
    </>
  );
}

export default RosePlansComponent;
