import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, NavLink, Link } from 'react-router-dom';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import logoBlack from '../../assets/images/logo_black.svg';
// import proimg from '../../assets/images/we-all-deserve.webp';
import verfied from '../../assets/images/verified.svg';
import notVerfied from '../../assets/images/verified_Grey.svg';
import AppConstants from '../../appConstants';
// import webServices from '../../services/webServices';
import authService from '../../services/authServices';
import NoActivePlanPopup from '../../pages/noActivePlanPopup';
import { getUserMatchCount } from '../../redux/actions/user.actions';
import InnerSidebar from './innerSidebar';
import { authCall } from '../../services';
import Section2remainderPopup from '../../pages/section2remainderPopup';
import VerifiedNotificationPopup from '../../pages/verifiedNotificationPopup';

const InnerHeader = ({ callBack }) => {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState('');
  const [profilePic, setProfilePic] = useState('male-female.png');
  const { pageTitle } = useSelector((state) => state.home);
  const { userMatchCount } = useSelector((state) => state.user);
  const { logout } = authService;
  const history = useHistory();
  // const [matchCount, setMatchCount] = useState(0);
  const [showActivePlan, setShowActivePlan] = useState(false);
  const handleCloseActivePlan = () => setShowActivePlan(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const { planDetails, current } = useSelector((state) => state.user);
  const [menuToggle1, setMenuToggle1] = useState(false);
  const [section2RemainderMsg, setSection2RemainderMsg] = useState(false);
  const handleCloseSection2Remainder = () => setSection2RemainderMsg(false);

  const [verifyMsg, setVerifyMsg] = useState(false);
  const handleCloseVerifyMsg = () => setVerifyMsg(false);

  const handleClickMenuToggle1 = (status) => {
    setMenuToggle1(status);
  };
  const callbackfunction = () => {
    callBack(true);
  };

  useEffect(() => {
    if (current?.status !== 'ACTIVE') {
      // toast.dismiss();
      // toast.error(
      //   `Unauthorized User! It seems your account is not activated; please try to login after it is activated.`
      // );
      // console.log('current', current);
      // setTimeout(() => {
      //   setIsSpinner(false);
      //   history.push({
      //     pathname: AppConstants.routes.home
      //   });
      // }, 1000);
    }
  }, []);

  useEffect(async () => {
    setFullName(
      `${current?.first_name || ''} ${current?.last_name || ''}`.trim()
    );
    setProfilePic(current?.profile_image || 'profile_pic.svg');
  }, [current]);

  const handleClickMenuToggle = (status) => {
    setMenuToggle(status);
  };

  // const getMatchCount = async () => {
  //   const resp = await webServices.getMatchCount();
  //   if (resp && resp.success === true) {
  //     setMatchCount(resp.data.count);
  //   }
  // };

  useEffect(async () => {
    // getMatchCount();
    dispatch(getUserMatchCount());
  }, []);

  const handleRedirectChat = async () => {
    const resp = await authCall('user/userCheckPermission', { action: 'chat' });
    if (resp.data === 'profileNotComplete') {
      setSection2RemainderMsg(true);
    } else if (resp.data === 'profileNotApproved') {
      history.push(AppConstants.routes.congratulations);
    } else if (resp.data === 'noActivePlan') {
      setShowActivePlan(true);
    } else if (userMatchCount > 0) {
      history.push({
        pathname: AppConstants.routes.chat
      });
    } else {
      setMenuToggle(false);
      toast.dismiss();
      toast.error(
        `We haven’t found any matches for you yet. While we continue to look for the best profiles that match your preferences, feel free to update your profile settings.`
      );
      setTimeout(() => {
        history.push({
          pathname: AppConstants.routes.afterLogin
        });
      }, 3000);
    }
  };

  const handleRedirectMyMatch = () => {
    if (userMatchCount > 0) {
      history.push({
        pathname: AppConstants.routes.matchQueue
      });
    } else {
      setMenuToggle(false);
      toast.dismiss();
      toast.error(
        `We haven’t found any matches for you yet. In the meantime, you may update your matching preferences to increase your chances of getting a match.`
      );
      setTimeout(() => {
        history.push({
          pathname: AppConstants.routes.afterLogin
        });
      }, 3000);
    }
  };

  const isProfileLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.publicProfile].includes(pathname),
    []
  );
  const isMatchQueueLinkActive = useCallback(
    (match, { pathname }) =>
      [AppConstants.routes.afterLogin].includes(pathname),
    []
  );
  const isPlansLinkActive = useCallback((match, { pathname }) =>
    [
      AppConstants.routes.plans,
      AppConstants.routes.rosePlans,
      AppConstants.routes.billingDetails,
      AppConstants.routes.plansView
    ].includes(pathname)
  );
  const isSettingLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.settings].includes(pathname)
  );

  const isChatLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.chat].includes(pathname)
  );

  const isMyMatchLinkActive = useCallback((match, { pathname }) =>
    [AppConstants.routes.matchQueue].includes(pathname)
  );

  return (
    <>
      <div className="innerHeader mobile">

        <div
          className="mobile-menu d-none"
          onClick={callbackfunction}
          aria-hidden="true"
        >
          <i className="fa fa-bars" aria-hidden="true" />
        </div>

        <header className="d-flex align-items-center">

          <div className="col-md-4 col-12 text-md-left text-center mobile-top">
            <div className='d-flex'>
              <div className="desktop-menu">
                <div
                  className="burger"
                  aria-hidden="true"
                  onClick={() => handleClickMenuToggle(!menuToggle)}
                >
                  <span />
                  <span />
                  <span />
                </div>
              </div>
              <Link to="/match-queue" className="logo-db w-100">
                <img src={logoBlack} className="img-fluid" alt="Lovrican Logo" />
              </Link>
            </div>
          </div>
          <div className="col-md-4 text-md-center desktop-only">
            <div className="pageName">
              <h1>{pageTitle}</h1>
            </div>
          </div>

          <div className="col-md-4 text-md-right desktop-only">
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex">
                {pageTitle === 'Match Queue' && (
                  <div className="text-md-right desktop-only">
                    <div className="likeToday">
                      <div className="numeric">
                        {planDetails.dailyLikeLimit}
                      </div>
                      <span>likes left today</span>
                    </div>
                  </div>
                )}
                <div className="profileSection position-relative">
                  {/* <div className='likeToday'>
                <div className='numeric'>2</div>
                <span>likes left today</span>
              </div> */}
                  <span>
                    {current?.status === 'ACTIVE' && current?.phone !== null ? (
                      <img
                        src={verfied}
                        className="img-fluid verifid_img"
                        alt="arrow icon"
                        aria-hidden="true"
                      />
                    ) : (
                      <img
                        src={notVerfied}
                        className="img-fluid verifid_img cursor-pointer"
                        alt="arrow icon"
                        aria-hidden="true"
                        onClick={() => {
                          setVerifyMsg(true);
                        }}
                      />
                    )}
                  </span>
                  <span className="frfl_name" title={fullName}>
                    {fullName}
                  </span>
                  <Link
                    to={AppConstants.routes.publicProfile}
                    className="logo-db"
                  >
                    <img
                      src={process.env.REACT_APP_PIC_BASE_URL + profilePic}
                      className="img-fluid d-inline-block"
                      alt=" profile img"
                    />
                  </Link>
                  <span className="active" />
                </div>
              </div>

              <div className="three_dot">
                <div
                  className="toggle-icon"
                  aria-hidden="true"
                  onClick={() => handleClickMenuToggle1(!menuToggle1)}
                >
                  <span />
                  <span />
                  <span />
                </div>
                {menuToggle1 && <InnerSidebar />}
              </div>
            </div>
          </div>
        </header>
      </div>
      {/* mobile menu start */}
      <div className="mobile-view-inner">
        <div
          className="burger"
          aria-hidden="true"
          onClick={() => handleClickMenuToggle(!menuToggle)}
        >
          <span />
          <span />
          <span />
        </div>
        <div className="logo-mobile" role="button">
          <Link to="/match-queue" className="logo-db">
            <img src={logoBlack} className="img-fluid" alt="Lovrican Logo" />
          </Link>
        </div>
      </div>
      <div className={`navbar-mobile ${menuToggle ? 'block' : 'd-none'}`}>
        <div className="menu-wrapper">
          <div className="d-block position-relative">
            <button
              type="button"
              className="close"
              onClick={() => setMenuToggle(false)}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <ul className="list-unstyled">
            <li onClick={handleRedirectMyMatch} aria-hidden="true">
              <NavLink
                to="#0"
                activeClassName="active"
                isActive={isMyMatchLinkActive}
              >
                My Matches
              </NavLink>
            </li>
            <li onClick={handleRedirectChat} aria-hidden="true">
              <NavLink
                to="#0"
                activeClassName="active"
                isActive={isChatLinkActive}
              >
                Chat
              </NavLink>
            </li>
            <li>
              <NavLink
                to={AppConstants.routes.afterLogin}
                activeClassName="active"
                isActive={isMatchQueueLinkActive}
              >
                Match queue
              </NavLink>
            </li>
            <li>
              <NavLink
                to={AppConstants.routes.publicProfile}
                activeClassName="active"
                isActive={isProfileLinkActive}
              >
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                to={AppConstants.routes.settings}
                activeClassName="active"
                isActive={isSettingLinkActive}
              >
                Settings
              </NavLink>
            </li>
            <li>
              <NavLink
                to={AppConstants.routes.plansView}
                activeClassName="active"
                isActive={isPlansLinkActive}
              >
                Plans
              </NavLink>
            </li>
          </ul>
          <div className="d-block link-btn">
            <span
              className="round-button mt-0 active"
              role="button"
              aria-hidden="true"
              onClick={() => {
                logout(history);
              }}
            >
              Logout
            </span>
          </div>
        </div>
      </div>
      {/* mobile menu End */}
      <div className="mobile-only">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-8">
              <div className="d-flex left align-items-center">
                <span className="active mr-2" />
                <span className="profile-name-mbl">{fullName}</span>
                <span>
                  {current?.status === 'ACTIVE' && current?.phone !== null ? (
                    <img
                      src={verfied}
                      className="img-fluid verifid_img"
                      alt="arrow icon"
                      aria-hidden="true"
                    />
                  ) : (
                    <img
                      src={notVerfied}
                      className="img-fluid verifid_img cursor-pointer"
                      alt="arrow icon"
                      aria-hidden="true"
                      onClick={() => {
                        setVerifyMsg(true);
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex left align-items-center justify-content-end">
                {pageTitle === 'Match Queue' && (
                  <div className="mbl-like">
                    <div className="num_like">{planDetails.dailyLikeLimit}</div>
                    <div>Likes left today</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <NoActivePlanPopup
        modalStatus={showActivePlan}
        handleClose={handleCloseActivePlan}
        currentPlan={planDetails}
      />

      <Section2remainderPopup
        modalStatus={section2RemainderMsg}
        handleClose={handleCloseSection2Remainder}
        userDetails={current}
      />

      <VerifiedNotificationPopup
        modalStatus={verifyMsg}
        handleClose={handleCloseVerifyMsg}
        userDetails={current}
      />
    </>
  );
};

export default InnerHeader;
