import React, { useState } from 'react';
import Select from 'react-dropdown-select';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { setUser } from '../../redux/actions/user.actions';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import btnIcon from '../../assets/images/arrow-circle.svg';
import { authCall } from '../../services';
import RelationshipPopup from '../registerSections/RelationshipPopup';

const RELATIONSHIP_STATUS_ARR = [
  {
    value: 'Single',
    label: 'Single'
  },
  {
    value: 'Married',
    label: 'Married'
  },
  {
    value: 'Divorced',
    label: 'Divorced'
  }
];

const RelationshipStatusStepComponent = ({ userDetails, handleNext, step }) => {
  const dispatch = useDispatch();
  const [validator] = useState(new SimpleReactValidator());
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    showRelationshipPopup: false,
    relationshipStatus: userDetails?.relationshiptStatus ? {label: userDetails?.relationshiptStatus, value: userDetails?.relationshiptStatus} : {},
  });

  const handelRelationshipStatusChange = async (value) => {
    if (value[0].label === 'Married') {
      setValues({
        relationshipStatus: {label: value[0].label, value: value[0].label},
        showRelationshipPopup: true
      });
    } else {
      setValues({
        relationshipStatus: {label: value[0].label, value: value[0].label},
        showRelationshipPopup: false
      });
    }
  };

  const handleClosePopup = () => {
    setValues((prevValues) => ({ ...prevValues, showRelationshipPopup: false }));
  }

  const submitForm = async () => {
    if (validator.allValid()) {
      if (values.relationshipStatus.value === 'Married') {
        setValues((prevValues) => ({ ...prevValues, showRelationshipPopup: true }));
      } else {
        const formData = {
          relationship: JSON.stringify(values.relationshipStatus),
        };
        setLoading(true);
        const resp = await authCall('auth/register-section', formData);
        if (resp && resp.success === true) {
          dispatch(setUser(resp.data.user));
          handleNext(step);
          setLoading(false);
        }
        setLoading(false);
      }
    } else {
      validator.showMessages();
      setValues({ ...values });
    }
  };

  return (
    <>
      <section className="registerDetails section-one lattest-reg-screen">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
            <div className="col-12 text-center">
              <div className="progress">
                <div className="progress-bar step2" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-12 col-lg-12">
              <div className='subtitle'>Share your marital status with us so we can help you find out if Lovrican is the perfect fit for you!</div>
            </div>
          </div>
          <div className="row join-reg bottom-join inner_bottom">
            <div className="col-md-12 col-lg-12 pb-4 mb-1 mt-lg-2 custome_select">
              <Select
                placeholder="Marital status"
                autoComplete="off"
                options={RELATIONSHIP_STATUS_ARR}
                className="custom_input"
                values={Object.keys(values.relationshipStatus).length > 0 ? [values.relationshipStatus] : []}
                onChange={(value) => handelRelationshipStatusChange(value)}
              />
              {validator.message('relationshipStatus', values.relationshipStatus, 'required')}
            </div>
            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3 marb7 text-right">
              <button
                className={`new_next_btn ${loading ? 'hks-btn-disable' : ''}`}
                onClick={submitForm}
                onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                type="button"
                tabIndex="0"
                disabled={loading || values.showRelationshipPopup || false}
              >
                <img src={btnIcon} className="img-fluid" alt="Lovrican Logo" />
              </button>
            </div>
          </div>

        </div>
      </section>
      <RelationshipPopup
        modalStatus={values.showRelationshipPopup}
        handleClose={handleClosePopup}
      />
    </>
  );
};

export default RelationshipStatusStepComponent;
