import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
import '../../assets/scss/style.scss';
import '../../assets/scss/mobile.scss';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast, ToastContainer } from 'react-toastify';
import webServices from '../../services/webServices';
import playStore from '../../assets/images/google_play.webp';
import ChoiceImg from '../../assets/images/sharedCulture.webp';
import Diverse from '../../assets/images/Diverse.webp';
import Efficient from '../../assets/images/Efficient.webp';
import Cultured from '../../assets/images/cultured.webp';
import Transparent from '../../assets/images/Transparent.webp';
import Empowered from '../../assets/images/Empowered.webp';
import Empathy from '../../assets/images/Empathy.svg';
import community from '../../assets/images/community.svg';
import Excellence from '../../assets/images/Excellence.svg';
import shape from '../../assets/images/shape.svg';
import AppleStore from '../../assets/images/app_store.webp';
import mobile from '../../assets/images/Mobile.webp';
import icon1 from '../../assets/images/icon1.png';
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import icon4 from '../../assets/images/icon4.png';
import icon5 from '../../assets/images/icon5.png';
import testimonialsImg1 from '../../assets/images/ney_york.jpg';
import testimonialsImg3 from '../../assets/images/usa2.jpg';
import testimonialsImg4 from '../../assets/images/usa3.jpg';
import testimonialsImg5 from '../../assets/images/Mama_Liz.webp';
import testimonialsImg6 from '../../assets/images/usa5.jpg';
import AppConstants from '../../appConstants';
import { isEmpty, isValidEmail } from '../../helpers/validator';
import { authCall } from '../../services';
import HowLovricanWorkPopup from './HowLovricanWorkPopup';
import ConfirmEmailPopup from './ConfirmEmailPopup';
import HomeHeaderMobile from '../../layouts/partial/homeHeaderMobile';
// import { loginPopupAction } from '../../redux/actions/home.actions';

import africanLogo from '../../assets/images/Lovrican-Logo_2.svg';
import step1 from '../../assets/images/step1-lovrican.svg';
import step2 from '../../assets/images/Step2-Lovrican.png';
import step3 from '../../assets/images/Step3-Lovrican.svg';
import step4 from '../../assets/images/Step4-Lovrican.png';
import step5 from '../../assets/images/Step5-Lovrican.svg';

import nigeriaFlag from '../../assets/images/Nigeria-flag.png';
import kenyaFlag from '../../assets/images/kenya-flag.png';
import meetsuccss from '../../assets/images/african_couple_haging_lovrican.webp'


import 'react-slideshow-image/dist/styles.css';

class HomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.selectorRef = React.createRef(null);
    this.state = {
      selected: 0,
      email: '',
      validRequest: false,
      howLovricanWorkMsg: false,
      confirmEmailMsg: false,
      otp: 0,
      randomCountry: ['Nigerians', 'Kenyans', 'Ghanians'],
      randomCulture: [
        'Igbo',
        'Oromo',
        'Kikuyu',
        'Ashanti',
        'Zulu',
        'Sukuma',
        'Hausa',
        'Baganda',
        'Luo',
        'Bakongo Mandinka',
        'Somali',
        'Yoruba'
      ],
      isScroll: false,
      customerFormActive: 0
    };
    Aos.init({ duration: 1000 });
  }

  async componentDidMount() {
    if (!localStorage.getItem('countryCulture')) {
      const response = await webServices.getCountryCulture();
      if (response.success) {
        localStorage.setItem('countryCulture', JSON.stringify(response.data));
      }
    }
    // if (localStorage.getItem('countryCulture')) {
    //   const countryCulture = JSON.parse(localStorage.getItem('countryCulture'));
    //   setInterval(() => {
    //     this.setState({
    //       randomCountry: this._randomslice(countryCulture?.country, 5),
    //       randomCulture: this._randomslice(countryCulture?.culture, 12)
    //     });
    //   }, 7000);
    // }

    this.prev = window.scrollY;
    window.addEventListener('scroll', (e) => this.handleNavigation(e));
  }

  handleNavigation = (e) => {
    const { isScroll } = this.state;
    const window = e.currentTarget;
    if (!isScroll && this.prev < window.scrollY) {
      this.setState({ isScroll: true });
      if (localStorage.getItem('countryCulture')) {
        const countryCulture = JSON.parse(
          localStorage.getItem('countryCulture')
        );
        setInterval(() => {
          this.setState({
            randomCountry: this._randomslice(countryCulture?.country, 5),
            randomCulture: this._randomslice(countryCulture?.culture, 12)
          });
        }, 7000);
      }
    }
    // if (this.prev > window.scrollY) {
    //     console.log("scrolling up", this.prev);
    // } else if (this.prev < window.scrollY) {
    //     console.log("scrolling down", this.prev);
    // }
    this.prev = window.scrollY;
  };

  _randomslice = (ar, size) => {
    const newAr = [...ar];
    newAr.splice(Math.floor(Math.random() * ar.length), 1);
    return ar.length <= size + 1 ? newAr : this._randomslice(newAr, size);
  };

  validateForm = async () => {
    const { email } = this.state;
    if (isEmpty(email) || !isValidEmail(email)) {
      toast.dismiss();
      toast.error('Please enter a valid email.');
    } else {
      this.setState({ validRequest: true });
    }
  };

  // handleClickConfirmEmailkMsg = async (e) => {
  //   e.preventDefault();
  //   await this.validateForm();
  //   const { validRequest } = this.state;
  //   if (validRequest) {
  //     this.setState({
  //       confirmEmailMsg: true
  //     });
  //   }
  // };

  // submitForm = async () => {
  //   await this.validateForm();
  //   const { history } = this.props;
  //   const { validRequest, email } = this.state;
  //   if (validRequest) {
  //     this.setState({
  //       confirmEmailMsg: false
  //     });
  //     const resp = await authCall('auth/action/send-otp-to-email', {
  //       email
  //     });
  //     if (resp && resp.success === true) {
  //       history.push(`/register?email=${email}`);
  //     }
  //   }
  // };

  handleClickConfirmEmailkMsg = async (e) => {
    e.preventDefault();
    await this.validateForm();
    const { validRequest, email } = this.state;
    if (validRequest) {
      const resp = await authCall('auth/action/send-otp-to-email', {
        email
      });
      if (resp && resp.success === true) {
        this.setState({
          confirmEmailMsg: true,
          otp: resp?.otp
        });
      }
    }
  };

  submitForm = async () => {
    await this.validateForm();
    const { history } = this.props;
    const { validRequest, email, otp } = this.state;
    if (validRequest) {
      this.setState({
        confirmEmailMsg: false
      });
      // history.push(`/register?email=${email}`);
      history.push({
        pathname: '/register',
        search: `?email=${email}`,
        state: { otp }
      });
    }
  };

  handleSelect = (key) => {
    this.setState({
      selected: key
    });
  };

  // handleExpendText = () => {
  //   this.setState({
  //     expendText: true
  //   });
  // };

  // pageScroll = (id) =>  {
  //   const myDiv = document.getElementById(id);
  //   myDiv.scrollBy(0, 10); // horizontal and vertical scroll increments
  //   let scrolldelay = setTimeout(() => {
  //     this.pageScroll(id);
  //   }, 100); // scrolls every 100 milliseconds
  //   if ((myDiv.innerHeight + myDiv.pageYOffset) >= document.body.offsetHeight) {
  //     clearTimeout(scrolldelay);
  //     scrolldelay = setTimeout(() => {
  //       this.PageUp(id);
  //     }, 2000);
  //   }

  // }

  // PageUp = (id) => {
  //   const myDiv = document.getElementById(id);
  //   myDiv.scrollTo(0, 0);
  //   setTimeout(() => {
  //     this.pageScroll(id);
  //   }, 600);
  // }

  handleCancel = () => {
    this.setState({
      selected: 0
    });
  };

  handleCloseHowLovricanWorkMsg = () => {
    this.setState({
      howLovricanWorkMsg: false
    });
  };

  handleClickHowLovricanWorkMsg = () => {
    this.setState({
      howLovricanWorkMsg: true
    });
  };

  handleClickStump = () => {
    this.selectorRef.current.scrollIntoView();
    this.setState({
      customerFormActive: 1
    });
  };

  handleClickCustomerFormActive = (id) => {
    this.setState({
      customerFormActive: id
    });
  };

  render() {
    const { history } = this.props;
    const {
      selected,
      email,
      howLovricanWorkMsg,
      confirmEmailMsg,
      randomCountry,
      randomCulture,
      customerFormActive
    } = this.state;
    return (
      <>
        <Helmet>
          <title>Dating Site for Nigerian Professionals in America | Lovrican</title>
          <meta
            name="description"
            content="Lovrican helps you find your ideal Nigerian soulmate among the single Nigerian professionals in America. Lovrican specializes in high-quality Nigerian professionals you’d be proud to introduce to your family… people who share your culture, values, interests, and aspirations."
          />
          <meta name="keywords" content="Nigerian Dating Site in America" />
        </Helmet>

        <div className="home">
          <section className="banner-bg top-banner">
            <div
              className="gift-home"
              aria-hidden="true"
              onClick={() => history.push(AppConstants.routes.login)}
            >
              Member Login
            </div>

            {/* Mobile Header */}
            <HomeHeaderMobile />
            {/* End Mobile Header */}

            <div className="banner-caption love-text common-caption">
              <h1> Cultivating Love. Elevating Success.</h1>
              <p className="text-left sab-head">
                Start meeting affluent Nigerian singles in America
              </p>
              <form onSubmit={this.handleClickConfirmEmailkMsg}>
                <div className="col-md-12 join-links text-center p-0">
                  <div className="d-md-flex mt-4 mb-4 d-sm-flex mt-sm-2 mb-sm-2 w-100">
                    <div className="inputOuter">
                      <input
                        type="text"
                        placeholder="Enter your email"
                        autoComplete="false"
                        value={email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                    <span
                      className="round-button mt-0"
                      onClick={this.handleClickConfirmEmailkMsg}
                      onKeyDown={(event) =>
                        event.key === 'Enter' &&
                        this.handleClickConfirmEmailkMsg()
                      }
                      role="button"
                      tabIndex="0"
                    >
                      Join
                    </span>
                  </div>
                </div>
              </form>
              <p className="small-text">
                By continuing, you’re confirming that you’ve read and agree to
                our <br />
                <a
                  href={`${process.env.REACT_APP_BASE_URL}/terms-of-service`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a
                  href={`${process.env.REACT_APP_BASE_URL}/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </section>

          {/* need to remove this section start */}
          <section className="love-arpiration love_arp_first text-center d-none">
            <div className="container">
              <div className="max-container">
                {/* <h1 className="pb-2">
                  Meet African Singles living in the USA who are compatible with
                  you culturally and professionally.
                </h1> */}

                <div className="row">
                  <div className="col-md-12">
                    <h2 className="animateEffect">
                      With Lovrican’s advanced user-screening capabilities and
                      intelligent matching algorithm, you can meet reliable,
                      serious, and diligent:
                      {/* <span>
                        {randomCountry.map((country, i) => (
                          <Link to="/register">
                            {country}
                            {randomCountry.length - 1 !== i ? ',' : ''}{' '}
                          </Link>
                        ))}
                      </span> */}
                      <div className="effectLink">
                        <span>
                          {randomCountry.map((country, i) => (
                            <Link to="/register">
                              {country}
                              {randomCountry.length - 1 !== i ? ',' : ''}{' '}
                            </Link>
                          ))}
                        </span>
                      </div>
                      or <Link to="/register"> other Africans</Link> today, who
                      meet your life-partner preferences and may be living
                      around the corner from you in the USA.
                    </h2>

                    <h2 className="animateEffect">
                      Whether you are looking for a quality life partner who is:
                      <div className="effectLink2">
                        <span>
                          {randomCulture.map((culture, i) => (
                            <Link to="/register">
                              {culture}
                              {randomCulture.length - 1 !== i ? ',' : ''}{' '}
                            </Link>
                          ))}
                        </span>
                      </div>
                      or from any other African community, we’ve got you
                      covered!
                    </h2>

                    <p>
                      Don’t take our word for it. Experience the Lovrican
                      difference for yourself.{' '}
                      <Link to="/register">
                        Create your profile and find love today!
                      </Link>{' '}
                      Registration is quick and easy.
                    </p>
                    <p>Don’t keep love waiting…</p>
                    <div
                      className="text-left mt-4"
                      aria-hidden="true"
                      role="button"
                      type="button"
                      onClick={() => {
                        history.push('/register');
                      }}
                    >
                      <span
                        className="round-button purple-theme"
                        role="button"
                        aria-hidden="true"
                      >
                        Get Started
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 text-left">
                    <h4>Lovrican True Love Guarantee</h4>
                    <h5>
                      We are confident that{' '}
                      <span>
                        you’ll soon find your special someone in Lovrican. In
                        fact, we guarantee* it!&nbsp;
                      </span>
                      Here’s the deal: Meet someone who is an exceptional fit
                      for you personally, culturally, and professionally within
                      6 months, or enjoy the service—free!—for another 6 months.
                    </h5>
                    <p className="note">
                      * Note: The guarantee is a limited time offer only. Act
                      now to secure your spot!
                    </p>
                    <div className="text-left">
                      <span
                        className="round-button purple-theme mr-4 mb-3 mb-md-2"
                        role="button"
                        aria-hidden="true"
                        onClick={() => {
                          history.push('/register');
                        }}
                      >
                        Get Guarantee
                      </span>
                      <span
                        className="round-button green-theme mb-0 mb-md-2"
                        role="button"
                        aria-hidden="true"
                        onClick={() => {
                          history.push('/gift');
                        }}
                      >
                        Be a Matchmaker
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 text-left last-home-section">
                    <h5>
                      Lovrican is the dating site{' '}
                      <span>
                        loved by singles who want to meet their special someone
                        but don’t like dating
                      </span>{' '}
                      or have no time to waste on conventional dating and
                      regular online dating. That is because Lovrican helps them
                      find their “happily ever after love” in the fewest dates
                      possible.
                    </h5>
                    <h5>
                      The secret sauce:&nbsp;
                      <span>
                        Lovrican specializes in the kind of people you would be
                        eager to introduce to your mama
                      </span>{' '}
                      – people who are a great fit for you personally,
                      culturally, and professionally.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="reasons-section">
                <div className="max-container">
                  <h3 className="head">
                    Here are 12 reasons Lovrican is <br />
                    different from other dating sites.
                  </h3>
                </div>
                <div className="row ">
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box1">
                      <div className="number">1</div>
                      Lovrican recognizes that{' '}
                      <span>
                        culture
                        <br />
                        and place of origin{' '}
                      </span>
                      are major <br />
                      factors in selecting a mate, <br />
                      especially in the{' '}
                      <span>
                        value-driven <br />
                        African society.
                      </span>{' '}
                      That is why we <br />
                      created Lovrican as a{' '}
                      <span>
                        courtship <br />
                        haven for millions of single <br />
                        African professionals
                      </span>{' '}
                      scattered <br />
                      across the globe.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box2">
                      <div className="number">2</div>
                      Lovrican{' '}
                      <span>
                        pre-screens <br />
                        every applicant
                      </span>{' '}
                      to <br />
                      ensure they are real, <br />
                      sincere, and a good <br />
                      fit for the Lovrican <br />
                      community:{' '}
                      <span>
                        {' '}
                        diligent,
                        <br />
                        kind, and caring.
                      </span>{' '}
                      This <br />
                      ensures you only meet <br />
                      high-quality people your
                      <br />
                      mama would approve of.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box3">
                      <div className="number">3</div>
                      Lovrican allows you to{' '}
                      <span>
                        specify the <br />
                        unique matching criteria
                      </span>{' '}
                      for your <br />
                      potential matches to ensure you <br />
                      only meet quality people of your <br />
                      type. Whether you are looking for a <br />
                      quality life partner who is{' '}
                      <Link to="/register">
                        Igbo, <br />
                        Oromo, Kikuyu, Ashanti, Zulu, <br />
                        Sukuma, Hausa, Baganda, Luo, <br />
                        Bakongo Mandinka, Somali, <br />
                        Yoruba,
                      </Link>{' '}
                      or from any other African <br />
                      community, we’ve got you covered!
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box4">
                      <div className="number">4</div>
                      Lovrican is{' '}
                      <span>
                        designed with <br />
                        your privacy in mind
                      </span>{' '}
                      and <br />
                      gives you the tools to <br />
                      control what you want to <br />
                      share and with whom. <br />
                      Unlike other sites,{' '}
                      <span>
                        your <br />
                        profile is not made <br />
                        public.
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box5">
                      <div className="number">5</div>
                      Lovrican makes schedules and <br />
                      geographical barriers fade. It <br />
                      allows you to meet hundreds of <br />
                      professional African matches <br />
                      from the location of your choice <br />
                      around the world and at your <br />
                      own{' '}
                      <span>
                        convenience, no matter <br />
                        the time of day or night.
                      </span>
                      <br />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box6">
                      <div className="number">6</div>
                      Lovrican{' '}
                      <span>
                        saves you the cost of <br />
                        time and resources
                      </span>{' '}
                      spent on <br />
                      drinks, dinners, movies, phone <br />
                      calls, fares, and so on that are <br />
                      associated with conventional <br />
                      dating and regular online dating. <br />
                      With Lovrican, you only spend <br />
                      your time and resources on the <br />
                      pre-screened candidate who <br />
                      matches your criteria.
                      <br />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box7">
                      <div className="number">7</div>
                      Lovrican was born out of love <br />
                      for Africa. We created the <br />
                      best technology to <br />
                      <span>
                        conveniently bring together <br />
                        Africa’s young men and <br />
                        women
                      </span>{' '}
                      wherever they are <br />
                      around the world so that they <br />
                      can find love more easily <br />
                      while fulfilling their dreams of <br />a successful
                      career.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box8">
                      <div className="number">8</div>
                      Lovrican helps you{' '}
                      <span>
                        avoid <br />
                        uncomfortable environments
                      </span>{' '}
                      like <br />
                      bars, lounges, and clubs where <br />
                      distractions and privacy might be <br />
                      an issue. Even a place of worship <br />
                      can be uncomfortable if your <br />
                      agenda is looking for a mate; and <br />
                      good luck to you if you are <br />
                      looking for your soulmate at a bar <br />
                      or in a club.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box9">
                      <div className="number">9</div>
                      In most African <br />
                      societies, men usually <br />
                      initiate relationships. <br />
                      <span>
                        Lovrican gives both <br />
                        genders equal <br />
                        opportunity to enjoy <br />
                        the adventure and <br />
                        surprise of finding love.
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box10">
                      <div className="number">10</div>
                      Lovrican enables you to <br />
                      <span>
                        meet high-quality African <br />
                        professionals from the <br />
                        wide array of African <br />
                        cultures around the world:
                      </span>{' '}
                      <br />
                      from the Caribbean to the <br />
                      Americas, from Europe to <br />
                      Asia, and from Africa to <br />
                      Australia, all in one place.
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box11">
                      <div className="number">11</div>
                      Lovrican believes in <br />
                      delivering{' '}
                      <span>
                        value to <br />
                        our customers;
                      </span>{' '}
                      unlike <br />
                      our competitors,{' '}
                      <span>
                        we <br />
                        charge less and give <br />
                        you more.
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="reasons-box reasons-box12">
                      <div className="number">12</div>
                      Lovrican is{' '}
                      <span>
                        easy to use <br />
                        unlike other sites that <br />
                        complicate matchmaking.
                      </span>{' '}
                      <br />
                      You know what you want <br />
                      and Lovrican gives you a <br />
                      simple and flexible way of <br />
                      specifying that.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* need to remove this section end */}

          <section className="love-arpiration love_arp_first text-center">
            <div className="container">
              <div className='row tophead_newAdd'>
                <div className='col-md-4'>
                  <div className='wrap'>
                    <h3>100%</h3>
                    <p>Verified users.* Meet your beautiful, <br/>
                    brilliant, and caring single <br/>
Nigerian professional today</p>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='wrap'>
                    <h3>97%</h3>
                    <p>of our members
                      are <br/> College Graduates**</p>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='wrap'>  <h3>Guaranteed</h3>
                    <p>Find your soulmate within 6 months, <br/> or enjoy an additional 6 months of <br/> Lovrican service free on us****</p>
                  </div>

                </div>
                <div className='col-md-12'>
                  <div className='membership_note'>*Users verified using Lovrican’s rigorous verification process</div>
                  <div className='membership_note'>**Lovrican data as of June 10, 2024</div>
                  <div className='membership_note'>***Guarantee is only applicable to subscribed users</div>
                </div>
                </div>
               
              <div className="max-container">
                <h1 className="pb-2">
                  Meet Successful Nigerian Singles in America to Find Your Ideal
                  Soulmate Who Enriches You Personally and Professionally
                </h1>

                <div className="row">
                  <div className="col-md-12">
                    <h2>
                      Lovrican is the premier online destination for affluent
                      Nigerian singles in America.
                    </h2>
                    <p>
                      {' '}
                      Many Nigerians crave meeting an equally successful partner
                      who gets them culturally and professionally. At Lovrican,
                      we believe that true compatibility is the foundation of
                      every successful relationship. Our advanced matching
                      algorithm takes into account your interests, values, and
                      aspirations, ensuring that you are connected with
                      like-minded individuals who share your vision for the
                      future.
                    </p>
                    <p>
                      Whether you’re looking for someone who shares your
                      definition of family values, a partner who appreciates
                      your cultural heritage, or a companion who understands the
                      demands of your professional life, Lovrican is committed
                      to helping you find that special someone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* need to remove start */}

          <section className="reasons-section-mobile d-none">
            <div className="container">
              <h3 className="head">
                Here are 12 reasons Lovrican is different from other dating
                sites.
              </h3>
            </div>
            <div
              id="differentSite"
              className="carousel slide"
              data-ride="carousel"
              data-interval="false"
            >
              <ul className="carousel-indicators">
                <li
                  data-target="#differentSite"
                  data-slide-to="0"
                  className="active"
                />
                <li data-target="#differentSite" data-slide-to="1" />
                <li data-target="#differentSite" data-slide-to="2" />
                <li data-target="#differentSite" data-slide-to="3" />
                <li data-target="#differentSite" data-slide-to="4" />
                <li data-target="#differentSite" data-slide-to="5" />
                <li data-target="#differentSite" data-slide-to="6" />
                <li data-target="#differentSite" data-slide-to="7" />
                <li data-target="#differentSite" data-slide-to="8" />
                <li data-target="#differentSite" data-slide-to="9" />
                <li data-target="#differentSite" data-slide-to="10" />
                <li data-target="#differentSite" data-slide-to="11" />
              </ul>

              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="reasons-box reasons-box1">
                    <div className="number">1</div>
                    <div className="innerContent">
                      Lovrican recognizes that{' '}
                      <span>
                        culture
                        <br />
                        and place of origin{' '}
                      </span>
                      are major <br />
                      factors in selecting a mate, <br />
                      especially in the{' '}
                      <span>
                        value-driven <br />
                        African society.
                      </span>{' '}
                      That is why we <br />
                      created Lovrican as a{' '}
                      <span>
                        courtship <br />
                        haven for millions of single <br />
                        African professionals
                      </span>{' '}
                      scattered <br />
                      across the globe.
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box2">
                    <div className="number">2</div>
                    <div className="innerContent">
                      Lovrican{' '}
                      <span>
                        pre-screens <br />
                        every applicant
                      </span>{' '}
                      to <br />
                      ensure they are real, <br />
                      sincere, and a good <br />
                      fit for the Lovrican <br />
                      community:
                      <span>
                        {' '}
                        diligent, <br /> kind, and caring.
                      </span>{' '}
                      This <br /> ensures you only meet <br />
                      high-quality people your
                      <br />
                      mama would approve of.
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box3">
                    <div className="number">3</div>
                    <div className="innerContent">
                      Lovrican allows you to{' '}
                      <span>
                        specify the <br />
                        unique matching criteria
                      </span>{' '}
                      for your <br />
                      potential matches to ensure you <br />
                      only meet quality people of your <br />
                      type. Whether you are looking for a <br />
                      quality life partner who is{' '}
                      <Link to="/register">
                        Igbo, <br />
                        Oromo, Kikuyu, Ashanti, Zulu, <br />
                        Sukuma, Hausa, Baganda, Luo, <br />
                        Bakongo Mandinka, Somali, <br />
                        Yoruba,
                      </Link>{' '}
                      or from any other African <br />
                      community, we’ve got you covered!
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box4">
                    <div className="number">4</div>
                    <div className="innerContent">
                      Lovrican is{' '}
                      <span>
                        designed with <br />
                        your privacy in mind
                      </span>{' '}
                      and <br />
                      gives you the tools to <br />
                      control what you want to <br />
                      share and with whom. <br />
                      Unlike other sites,{' '}
                      <span>
                        your <br />
                        profile is not made <br />
                        public.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box5">
                    <div className="number">5</div>
                    <div className="innerContent">
                      Lovrican makes schedules and <br />
                      geographical barriers fade. It <br />
                      allows you to meet hundreds of <br />
                      professional African matches <br />
                      from the location of your choice <br />
                      around the world and at your <br />
                      own{' '}
                      <span>
                        convenience, no matter <br />
                        the time of day or night.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box6">
                    <div className="number">6</div>
                    <div className="innerContent">
                      Lovrican{' '}
                      <span>
                        saves you the cost of <br />
                        time and resources
                      </span>{' '}
                      spent on <br />
                      drinks, dinners, movies, phone <br />
                      calls, fares, and so on that are <br />
                      associated with conventional <br />
                      dating and regular online dating. <br />
                      With Lovrican, you only spend <br />
                      your time and resources on the <br />
                      pre-screened candidate who <br />
                      matches your criteria.
                      <br />
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box7">
                    <div className="number">7</div>
                    <div className="innerContent">
                      Lovrican was born out of love <br />
                      for Africa. We created the <br />
                      best technology to <br />
                      <span>
                        conveniently bring together <br />
                        Africa’s young men and <br />
                        women
                      </span>{' '}
                      wherever they are <br />
                      around the world so that they <br />
                      can find love more easily <br />
                      while fulfilling their dreams of <br />a successful
                      career.
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box8">
                    <div className="number">8</div>
                    <div className="innerContent">
                      Lovrican helps you{' '}
                      <span>
                        avoid <br />
                        uncomfortable environments
                      </span>{' '}
                      like <br />
                      bars, lounges, and clubs where <br />
                      distractions and privacy might be <br />
                      an issue. Even a place of worship <br />
                      can be uncomfortable if your <br />
                      agenda is looking for a mate; and <br />
                      good luck to you if you are <br />
                      looking for your soulmate at a bar <br />
                      or in a club.
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box9">
                    <div className="number">9</div>
                    <div className="innerContent">
                      In most African <br />
                      societies, men usually <br />
                      initiate relationships. <br />
                      <span>
                        Lovrican gives both <br />
                        genders equal <br />
                        opportunity to enjoy <br />
                        the adventure and <br />
                        surprise of finding love.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box10">
                    <div className="number">10</div>
                    <div className="innerContent">
                      Lovrican enables you to <br />
                      <span>
                        meet high-quality African <br />
                        professionals from the <br />
                        wide array of African <br />
                        cultures around the world:
                      </span>{' '}
                      <br />
                      from the Caribbean to the <br />
                      Americas, from Europe to <br />
                      Asia, and from Africa to <br />
                      Australia, all in one place.
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box11">
                    <div className="number">11</div>
                    <div className="innerContent">
                      Lovrican believes in <br />
                      delivering{' '}
                      <span>
                        value to <br />
                        our customers;
                      </span>{' '}
                      unlike <br />
                      our competitors,{' '}
                      <span>
                        we <br />
                        charge less and give <br />
                        you more.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="reasons-box reasons-box12">
                    <div className="number">12</div>
                    <div className="innerContent">
                      Lovrican is{' '}
                      <span>
                        easy to use <br />
                        unlike other sites that <br />
                        complicate matchmaking.
                      </span>{' '}
                      <br />
                      You know what you want <br />
                      and Lovrican gives you a <br />
                      simple and flexible way of <br />
                      specifying that.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="love-arpiration customer_feedback text-center d-none">
            <div className="container">
              <h1>
                {' '}
                Lovrican Success
                <br />
                Stories
              </h1>
              <div className="text-left left_shape">
                <img src={shape} alt="" />
              </div>

              <div className="testmonia_container">
                <div
                  id="customerFeedback"
                  className="carousel slide"
                  data-ride="carousel"
                  data-interval="false"
                >
                  <ul className="carousel-indicators">
                    <li
                      data-target="#customerFeedback"
                      data-slide-to="0"
                      // className="active"
                      className={customerFormActive === 0 ? `active` : ''}
                      onClick={() => this.handleClickCustomerFormActive(0)}
                      aria-hidden="true"
                    />
                    <li
                      data-target="#customerFeedback"
                      data-slide-to="1"
                      className={customerFormActive === 1 ? `active` : ''}
                      onClick={() => this.handleClickCustomerFormActive(1)}
                      aria-hidden="true"
                    />
                    <li
                      data-target="#customerFeedback"
                      data-slide-to="2"
                      className={customerFormActive === 2 ? `active` : ''}
                      onClick={() => this.handleClickCustomerFormActive(2)}
                      aria-hidden="true"
                    />
                    <li
                      data-target="#customerFeedback"
                      data-slide-to="3"
                      className={customerFormActive === 3 ? `active` : ''}
                      onClick={() => this.handleClickCustomerFormActive(3)}
                      aria-hidden="true"
                    />
                    <li
                      data-target="#customerFeedback"
                      data-slide-to="4"
                      className={customerFormActive === 4 ? `active` : ''}
                      onClick={() => this.handleClickCustomerFormActive(4)}
                      aria-hidden="true"
                    />
                  </ul>

                  <div className="carousel-inner" ref={this.selectorRef}>
                    <div
                      className={`carousel-item ${customerFormActive === 0 ? 'active' : ''
                        }`}
                    >
                      <div className="customerFeedback_inner">
                        <h2 className="name-top">Gamba & Sukutai</h2>
                        <div className="height-wrapper">
                          <div className="user-thumnail">
                            <img
                              src={testimonialsImg3}
                              alt=""
                              className="thumnail-img"
                            />
                            <h5 className="name">Gamba and Sukutai</h5>
                            <div className="city">San Diego, USA</div>
                          </div>
                          <div className="user-feedback">
                            <p>
                              Way back when our grandparents were younger,
                              existed a society, especially in most African
                              communities, where your parents picked or at least
                              helped you find your life partner. Then there was
                              a period in our parents’ generation when people
                              stayed close to home and married their childhood
                              friends and high school sweethearts. But life is
                              different now. We live in a faster, more demanding
                              society that is highly transient. Our generation
                              is left without the family and friends’ support
                              system available to earlier generations,
                              especially when it comes to finding a life
                              partner. To me, online dating is just another way
                              of adjusting to the changes in the world we live
                              in and{' '}
                              <span>Lovrican is just it for Africans.</span>
                            </p>

                            <p>
                              That is why, when I finished my MBA, I joined
                              Lovrican. A few weeks later, I met my fiancée
                              Sukutai. I helped her set up her dentistry
                              practice as I looked for a job and our bond has
                              grown stronger ever since. I have since gotten a
                              job and her dental practice is taking off.{' '}
                              <span>
                                It is great to have someone this supportive in
                                my life.
                              </span>
                            </p>

                            <p>
                              We have met both parents and they are all very
                              happy for us. At first, my mother was concerned
                              when I told her how we met. However, after she got
                              to know Sukutai, she has become a big advocate for
                              Lovrican—you should hear her pushing my sister to
                              try it.
                            </p>

                            <p>
                              <span>
                                Sukutai and I are getting married next summer.
                                For once in my life I feel truly happy and I
                                want to thank Lovrican for getting us together.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`carousel-item ${customerFormActive === 1 ? 'active' : ''
                        }`}
                    >
                      <div className="customerFeedback_inner">
                        <h2 className="name-top">
                          Mama Liz - Matchmaker for Liz & Ben
                        </h2>
                        <div className="height-wrapper">
                          <div className="user-thumnail">
                            <img
                              src={testimonialsImg5}
                              alt=""
                              className="thumnail-img"
                            />
                            <h5 className="name">
                              Mama Liz - Matchmaker for Liz & Ben
                            </h5>
                            <div className="city">Houston, Texas</div>
                          </div>
                          <div className="user-feedback">
                            <p>
                              My daughter Elizabeth was always clear on what she
                              wanted in life. When she was only 12, I remember
                              her telling me how she’d one day be a lawyer. By
                              then, she was already taking advanced placement
                              classes. I worried that she was not spending
                              enough time being a kid, but a fire deep inside
                              her seemed to be driving her choices and nothing
                              interested her more than gaining knowledge.
                            </p>

                            <p>
                              When she graduated law school and started working
                              in New York, I could not be happier to see one of
                              her life’s dreams fulfilled.{' '}
                              <span>
                                She now wanted to start a family with a man who
                                was rooted in African culture—“a real man, like
                                dad,”
                              </span>{' '}
                              as she put it. But there were no Africans at her
                              job and the long hours in the office left her with
                              little time to venture into events where she might
                              have met the kind of man she wanted for a life
                              partner.
                            </p>
                            <p>
                              As a mother, these things worry you. Seeing a need
                              in your daughter and not knowing how to help keeps
                              you up at night. That’s why when a fellow mother
                              shared in our WhatsApp group the link to Lovrican
                              and I saw the stamp “approved by African Mothers”
                              I immediately wanted to learn more.{' '}
                              <span>
                                I was pleased to discover the Lovrican
                                Matchmaker service,
                              </span>{' '}
                              where you basically buy the Lovrican service for
                              someone and Lovrican then gets them enrolled and
                              connects them with quality single African
                              professionals.
                            </p>
                            <p>
                              <span>
                                The matchmaker package turned out to be the best
                                gift I have ever given.
                              </span>{' '}
                              Liz was a bit reluctant at first—online dating is
                              not her thing. So, I offered to help her create
                              her profile. It was quite fun as we created her
                              introductory summary and sifted through her photos
                              to select the ones to use for her profile. “Mom,
                              not that one. No man wants to marry a bookworm,”
                              she would tell me, laughing.
                            </p>
                            <p>
                              About four months later, she was matched with Ben.
                              She called me in the middle of the night to tell
                              me about him.{' '}
                              <span>
                                He was everything she was looking for in a
                                man—tall, dark, handsome, spiritual, and a great
                                head on his shoulders.
                              </span>{' '}
                              She was so excited that I worried for her. “What
                              if she gets heartbroken?” I thought. But it’s
                              working out great. As she told me, Ben was “a real
                              man, just like dad.” They have been dating for six
                              months now and she is as excited about him as she
                              was that first night when she called to tell me
                              about him.{' '}
                              <span>
                                We have met Ben and we now know why she is so
                                crazy about him. He is a splendid young man from
                                a wonderful family.
                              </span>{' '}
                              Liz and Ben have not said anything about marriage,
                              but there is every sign they’re heading in that
                              direction.
                            </p>
                            <p>
                              As a mother, Lovrican was a savior.{' '}
                              <span>
                                The Matchmaker service is such a creative
                                approach that saved me from sleepless nights and
                                got my Liz the love of her life.
                              </span>{' '}
                              Thank you Lovrican for being such a blessing to my
                              family and for all that you are doing for the
                              African community.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`carousel-item ${customerFormActive === 2 ? 'active' : ''
                        }`}
                    >
                      <div className="customerFeedback_inner">
                        <h2 className="name-top">Ajani & Oba</h2>
                        <div className="height-wrapper">
                          <div className="user-thumnail">
                            <img
                              src={testimonialsImg6}
                              alt=""
                              className="thumnail-img"
                            />
                            <h5 className="name">Ajani and Oba</h5>
                            <div className="city">Atlanta, Georgia</div>
                          </div>
                          <div className="user-feedback">
                            <p>
                              After living in the US for 8 years, I could feel
                              it in my bones:{' '}
                              <span>
                                I needed someone from my culture to settle down
                                with.
                              </span>{' '}
                              I strategized and started going out more. I
                              organized a few parties at my house and even
                              frequently visited my friends out of town in the
                              hope of meeting someone special. But nothing
                              happened. I couldn’t help wondering—what am I
                              missing?
                            </p>

                            <p>
                              I believe that a man should be a man –
                              responsible, strong, loving, and respectful to the
                              women in his life. I am a big advocate for gender
                              equality and I also believe there are things that
                              both men and women do better than the other. I do
                              not know if there is a scientific explanation for
                              this. I was concerned about the behavior of most
                              of the single African girls I met. They seemed
                              very confused trying to fit in with the “American
                              women” image – whatever that is. I have heard my
                              mother tell my sister many times, “If you respect
                              a man, only a foolish man will not respect you
                              back…and you don’t need a foolish man in your
                              life.” I am sure most African girls have heard
                              something similar from their mothers.
                            </p>

                            <p>
                              I decided against most of my friends. I decided to
                              seek a girl from back home.{' '}
                              <span>
                                Lovrican allowed me to specify the country I
                                wanted my matches to be from.
                              </span>{' '}
                              Three months later, I met Oba. She had just
                              finished her medical degree at a local university
                              and was about to come to the US for her residency.
                              Three months after we were matched in Lovrican we
                              decided to meet in Lagos.{' '}
                              <span>
                                It did not take long to realize she was a jewel.
                                She was beautiful, smart, respectable, and
                                strong in character. I was blown away. I did not
                                imagine a woman this good existed.
                              </span>{' '}
                              I thought I was good but she made me want to be a
                              better man. The kind of man that I know she
                              deserves.
                            </p>

                            <p>
                              We now live together and her residency is going
                              well. Oba is everything I ever wanted in a woman
                              and more – much more.{' '}
                              <span>
                                Thank you Lovrican for bringing us together and
                                I hope you do the same for others.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`carousel-item ${customerFormActive === 3 ? 'active' : ''
                        }`}
                    >
                      <div className="customerFeedback_inner">
                        <h2 className="name-top">Mwangi & Njeri</h2>
                        <div className="height-wrapper">
                          <div className="user-thumnail">
                            <img
                              src={testimonialsImg1}
                              alt=""
                              className="thumnail-img"
                            />
                            <h5 className="name">Mwangi and Njeri</h5>
                            <div className="city">New York City, New York</div>
                          </div>
                          <div className="user-feedback">
                            <p>
                              <span>
                                I tried Lovrican because of my conservative
                                upbringing and the need to be private.
                              </span>{' '}
                              I have been very skeptical of meeting people
                              online but I remembered a saying that I heard
                              once—“In life, you regret things that you don’t
                              attempt more than those that you do attempt.” I
                              have always operated on the principle of trying
                              everything once and quiting if i don’t like it. I
                              figured that it wouldn’t hurt to make a few
                              friends online, if nothing else.{' '}
                              <span>
                                I needed to meet people outside of my daily
                                routine and work
                              </span>{' '}
                              – anyone who lives in the USA probably understands
                              well what I mean. You have little free time and
                              you tend to be locked into a pattern of work,
                              school, and a few hobbies. It is very difficult to
                              meet new people. It is even more difficult to meet
                              new quality people.
                            </p>

                            <p>
                              <span>
                                My dream was to meet somebody from my country
                                who lives in the US and is a professional like
                                me.
                              </span>{' '}
                              After five years in the USA and still single, the
                              idea of meeting a new group of friends was
                              exciting. I knew more of the kind of person I did
                              not want than the kind of person I wanted. One
                              thing I was sure of was that I wanted was a
                              professional like me.
                            </p>
                            <p>
                              I kept having this preconceived idea of a perfect
                              mate but I knew I needed to keep an open mind.
                              Life is never perfect. A few weeks after joining
                              Lovrican, one of my matches caught my interest.
                              After about a month of talking online, I decided
                              to go against my African tradition. I became the
                              initiator. I asked him for a date.{' '}
                              <span>
                                We met at a coffee shop in a local mall for my
                                safety. The guy turned out to be even better
                                than I thought, surpassing my high expection. He
                                was even more interesting in person.
                              </span>{' '}
                              It took a few dates to break the ice. I had to
                              check his background too – it is just part of due
                              diligence and he did not mind it. But he is a
                              wonderful man and our friendship turned into this
                              wonderful relationship.{' '}
                              <span>
                                We are now engaged and making plans for the
                                wedding.
                              </span>
                            </p>
                            <p>
                              We want to thank Lovrican for bringing us together
                              and we hope you can help others find love and
                              happiness like we have.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`carousel-item ${customerFormActive === 4 ? 'active' : ''
                        }`}
                    >
                      <div className="customerFeedback_inner">
                        <h2 className="name-top">Kofi & Mansa</h2>
                        <div className="height-wrapper">
                          <div className="user-thumnail">
                            <img
                              src={testimonialsImg4}
                              alt=""
                              className="thumnail-img"
                            />
                            <h5 className="name">Kofi and Mansa</h5>
                            <div className="city">Los Angeles, California</div>
                          </div>
                          <div className="user-feedback">
                            <p>
                              I’m a real estate agent here in California. When
                              looking for a property, they say location,
                              location, location. African women of my age and
                              caliber are at the wrong location as far as men
                              are concerned. Being a financially secure
                              professional seemed to work against me – I was
                              always short of time and most guys I knew made
                              less than me and seemed intimidated. African men
                              will be African men. I could have married local
                              Americans like some of my friends. I even dated
                              some but I couldn’t imagine living with them.{' '}
                              <span>
                                I wished I could put men – African men – in one
                                big pot and filter them until I get the right
                                one, my Mr. Perfect. I found that filter in
                                Lovrican.{' '}
                              </span>
                            </p>

                            <p>
                              <span>
                                My mother likes my choice of a man. That is a
                                first.
                              </span>{' '}
                              My advice to African singles worldwide, including
                              those in African urban centers is to proactively
                              play on the offensive side. You’ll likely be
                              pleasantly surprised. I was.{' '}
                              <span>
                                Kofi is THE MAN I was always meant to be with.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="text-right right_shape">
                <img src={shape} alt="" />
              </div> */}
            </div>
          </section>

          <section className="find-true-love d-none">
            <div className="container">
              <h2>Are you next to find true Love?</h2>
              <div className="mt-4">
                <span
                  className="round-button purple-theme mr-0 mr-md-4 mt-3 mb-md-2"
                  role="button"
                  aria-hidden="true"
                  onClick={() => {
                    history.push('/register');
                  }}
                >
                  Find Love
                </span>
                <span
                  className="round-button green-theme mb-0 mb-md-2 mt-3"
                  role="button"
                  aria-hidden="true"
                  onClick={() => {
                    history.push('/gift');
                  }}
                >
                  Be a Matchmaker
                </span>
              </div>
            </div>
          </section>
          {/* need to remove start */}
          <section className="love-arpiration customer_feedback text-center desktop-version">
            <div className="container-fluid">
              <h1>
                {' '}
                Top Occupations
                <br />
                of Lovrican Users
              </h1>
              <div className="text-left left_shape">
                <img src={shape} alt="" />
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="box box1">
                    
                    <div className="name">Healthcare</div>
                    <ul>
                      <li>Medical Doctors</li>
                      <li>Pharmacists</li>
                      <li>Nurses</li>
                      <li>Physician Assistants</li>
                      <li>Medical Technicians</li>
                      <li>Counselors and <br/>Therapists</li>
                      <li>Pharmacy <br/>Technicians</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="box box2">
                  <div className="name">Engineering and Construction</div>
                    <ul>
                      <li>Civil Engineers - <br/>Buildings, Dams, Roads</li>
                      <li>Mechanical Engineers</li>
                      <li>Aerospace Engineers</li>
                      <li>Architects</li>
                      <li>Electrical Engineers</li>
                      <li>Manufacturing Engineers</li>
                      <li>Energy Production and <br/>Supply Engineers</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="box box3">
                  <div className="name">Business Management</div>
                    <ul>
                      <li>Executive Corporate <br/>Managers</li>
                      <li>Entrepreneurs</li>
                      <li>Sales & Marketing <br/>Professionals</li>
                      <li>Finance Managers</li>
                      <li>Management Consultants</li>
                      <li>Accountants</li>
                      <li>Lawyers</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="box box4">
                  <div className="name">Information Technology</div>
                    <ul>
                      <li>Software Engineers</li>
                      <li>AI and Analytics <br/>Engineers</li>
                      <li>Cloud Engineers</li>
                      <li>Network Engineers</li>
                      <li>UI / UX Designers</li>
                      <li>Cybersecurity <br/>Specialists</li>
                      <li>System Architects</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="box box5">
                   
                  <div className="name">Science and Education</div>
                    <ul>
                      <li>Researchers & <br/>Scientists</li>
                      <li>University Professors</li>
                      <li>PhD Students</li>
                      <li>Masters Students</li>
                      <li>Undergraduate <br/>Students</li>
                      <li>Environmentalists</li>
                      <li>Agriculturalists</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="box box6">
                  <div className="name">Arts, Fashion & Entertainment</div>
                    <ul>
                      <li>Actors and Actresses</li>
                      <li>Musicians</li>
                      <li>Movie Directors</li>
                      <li>Media productions Specialists</li>
                      <li>Beautician and <br/>Makeup Artists</li>
                      <li>Event Organizers and <br/>Planners</li>
                      <li>Fashion Designers</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="text-right right_shape">
                <img src={shape} alt="" />
              </div>
            </div>
          </section>
          <section className="love-arpiration customer_feedback text-center mobile-version">
            <div className="container-fluid">
              <h1>
                {' '}
                From our
                <br />
                Customers
              </h1>
              <div className="text-left left_shape">
                <img src={shape} alt="" />
              </div>
              <div
                id="customer_feedback_new"
                className="carousel slide"
                data-ride="carousel"
                data-interval="false"
              >
                <ul className="carousel-indicators">
                  <li
                    data-target="#customer_feedback_new"
                    data-slide-to="0"
                    className="active"
                  />
                  <li data-target="#customer_feedback_new" data-slide-to="1" />
                  <li data-target="#customer_feedback_new" data-slide-to="2" />
                  <li data-target="#customer_feedback_new" data-slide-to="3" />
                  <li data-target="#customer_feedback_new" data-slide-to="4" />
                  <li data-target="#customer_feedback_new" data-slide-to="5" />
                </ul>

                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="box box1">
                    <div className="name">Healthcare</div>
<ul>
  <li>Medical Doctors</li>
  <li>Pharmacists</li>
  <li>Nurses</li>
  <li>Physician Assistants</li>
  <li>Medical Technicians</li>
  <li>Counselors and <br/>Therapists</li>
  <li>Pharmacy <br/>Technicians</li>
</ul>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box2">
                    <div className="name">Engineering and Construction</div>
<ul>
  <li>Civil Engineers - <br/>Buildings, Dams, Roads</li>
  <li>Mechanical Engineers</li>
  <li>Aerospace Engineers</li>
  <li>Architects</li>
  <li>Electrical Engineers</li>
  <li>Manufacturing Engineers</li>
  <li>Energy Production and <br/>Supply Engineers</li>
</ul>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box3">
                    <div className="name">Business Management</div>
                    <ul>
                      <li>Executive Corporate <br/>Managers</li>
                      <li>Entrepreneurs</li>
                      <li>Sales & Marketing <br/>Professionals</li>
                      <li>Finance Managers</li>
                      <li>Management Consultants</li>
                      <li>Accountants</li>
                      <li>Lawyers</li>
                    </ul>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box4">
                    <div className="name">Information Technology</div>
                    <ul>
                      <li>Software Engineers</li>
                      <li>AI and Analytics <br/>Engineers</li>
                      <li>Cloud Engineers</li>
                      <li>Network Engineers</li>
                      <li>UI / UX Designers</li>
                      <li>Cybersecurity <br/>Specialists</li>
                      <li>System Architects</li>
                    </ul>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box5">
                    <div className="name">Science and Education</div>
                    <ul>
                      <li>Researchers & <br/>Scientists</li>
                      <li>University Professors</li>
                      <li>PhD Students</li>
                      <li>Masters Students</li>
                      <li>Undergraduate <br/>Students</li>
                      <li>Environmentalists</li>
                      <li>Agriculturalists</li>
                    </ul>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="box box6">
                    <div className="name">Arts, Fashion & Entertainment</div>
                    <ul>
                      <li>Actors and Actresses</li>
                      <li>Musicians</li>
                      <li>Movie Directors</li>
                      <li>Media productions Specialists</li>
                      <li>Beautician and <br/>Makeup Artists</li>
                      <li>Event Organizers and <br/>Planners</li>
                      <li>Fashion Designers</li>
                    </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right right_shape">
                <img src={shape} alt="" />
              </div>
            </div>
          </section>
          <section className="love-arpiration love_arp_second text-center">
            <div className="container">
              <div className="max-container">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="pl-0 pb-4 mt-4 mt-lg-0 mb-4 mt-lg-0">
                      How Lovrican Works
                    </h2>
                    <div className="row lov-work">
                      <div className="col-md-3">
                        <img className="img-fluid" src={icon1} alt="" />
                      </div>
                      <div className="col-md-9 text-left">
                        <div className="d-flex">
                          <div className="sno text-center">
                            <div className="circle">1</div>
                          </div>
                          <div className="box">
                            <div className="head">
                              Express Your Interest and Submit Your Basic
                              Profile.
                            </div>
                            <div className="dec">
                              Ready to embark on your journey to find love,
                              success, and cultural connection? Joining Lovrican
                              is as simple as expressing your interest and
                              submitting your basic profile.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mt-lg-2 mt-4">
                        <img className="img-fluid" src={icon2} alt="" />
                      </div>
                      <div className="col-md-9 text-left">
                        <div className="d-flex mt-3">
                          <div className="sno text-center">
                            <div className="circle">2</div>
                          </div>
                          <div className="box">
                            <div className="head">
                              Expert Screening and Approval.
                            </div>
                            <div className="dec">
                              At Lovrican, we take great pride in our exclusive
                              community. We review profiles with care and
                              consideration, approving those that align with our
                              high standards of success, affluence, and shared
                              values.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mt-lg-2 mt-4">
                        <img className="img-fluid" src={icon3} alt="" />
                      </div>
                      <div className="col-md-9 text-left">
                        <div className="d-flex mt-3">
                          <div className="sno text-center">
                            <div className="circle">3</div>
                          </div>
                          <div className="box">
                            <div className="head">
                              Explore Curated and Pre-Screened Matches.
                            </div>
                            <div className="dec">
                              In your match queue, you’ll find a selection of
                              exceptional individuals who have captured our
                              attention, matching your preferences and
                              aspirations.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mt-lg-2 mt-4">
                        <img className="img-fluid" src={icon4} alt="" />
                      </div>
                      <div className="col-md-9 text-left">
                        <div className="d-flex mt-3">
                          <div className="sno text-center">
                            <div className="circle">4</div>
                          </div>
                          <div className="box">
                            <div className="head">
                              Connect with Highly Compatible Matches.
                            </div>
                            <div className="dec">
                              Based on your unique profile, we’ll connect you
                              with professional Nigerian singles in America who
                              align with your vision for love and success.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 mt-lg-2 mt-4">
                        <img className="img-fluid" src={icon5} alt="" />
                      </div>
                      <div className="col-md-9 text-left">
                        <div className="d-flex d-flex mt-4">
                          <div className="sno text-center">
                            <div className="circle">5</div>
                          </div>
                          <div className="box border-0">
                            <div className="head">
                              Discover Your Special Nigerian Soulmate.
                            </div>
                            <div className="dec">
                              Prepare yourself for an incredible journey as
                              Lovrican guides you toward discovering your
                              special Nigerian soulmate.
                            </div>
                          </div>
                        </div>
                        <div
                          className="read-more"
                          onClick={this.handleClickHowLovricanWorkMsg}
                          aria-hidden="true"
                        >
                          <span className="dot_wrapper">
                            <span className="dot" />
                            <span className="dot" />
                            <span className="dot" />
                          </span>{' '}
                          READ MORE
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="centerBox">
                  <div className="row">
                    <div className="col-md-12">
                      <p>Find your special Nigerian soulmate</p>
                      <span
                        className="round-button"
                        role="button"
                        aria-hidden="true"
                        onClick={() => history.push('/register')}
                      >
                        Join
                      </span>
                    </div>
                  </div>
                  <div className="row mt-4 pt-4">
                    <div className="col-md-12">
                      <p>
                        Set up a friend or relative on a date with an
                        exceptional Nigerian single
                      </p>
                      <span
                        className="round-button green-theme"
                        role="button"
                        aria-hidden="true"
                        onClick={() => history.push(AppConstants.routes.gift)}
                      >
                        Be a Matchmaker
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="personSection banner-bg">
            <div className="banner-caption love-text">
              <div className={`box ${selected === 11 ? 'active' : ''}`}>
                <button
                  type="button"
                  className="close d-none"
                  data-dismiss="alert"
                  onClick={this.handleCancel}
                >
                  ×
                </button>
                <h1 data-aos="zoom-in-down">
                  Tailored Features for Your Convenience
                </h1>
                <Scrollbars className="scroll-height">
                  <div
                    // className="fixContent"
                    onClick={() => this.handleSelect(11)}
                    aria-hidden="true"
                    style={{ cursor: 'pointer' }}
                  >
                    <p className={`${selected === 11 ? '' : 'expandText'}`}>
                      <span data-aos="zoom-in-down">
                        {/* <span className="top">
                Wouldn’t it be wonderful to meet a culturally
                compatible special soulmate who is just as successful
                as you?
              </span> */}
                        <span className="bottom">
                          We’ve designed Lovrican with your convenience in mind.
                          Our user-friendly interface and intuitive features
                          make it easy for you to navigate and explore the
                          platform. From advanced search filters that allow you
                          to specify your desired criteria to personalized
                          matchmaking suggestions, we strive to ensure that your
                          experience on Lovrican is seamless and enjoyable.
                        </span>
                      </span>
                      <span className="bottom">
                        Additionally, our responsive design lets you stay
                        connected on the go, so you can browse profiles, chat
                        and arrange dates wherever and whenever you choose.
                      </span>
                    </p>
                  </div>
                </Scrollbars>
                <span
                  // data-aos="zoom-in-down"
                  className="dot_wrapper mt-2"
                  onClick={() => this.handleSelect(11)}
                  aria-hidden="true"
                  style={{ cursor: 'pointer' }}
                >
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </span>
              </div>
            </div>
          </section>

          <section className="this_is_your_comunity banner-bg">
            <div className="banner-caption love-text text-left">
              <h1 data-aos="zoom-in-down">This is Your Community</h1>
              <p data-aos="zoom-in-down">
                Lovrican is designed to meet the unique needs of highly
                successful Nigerian professionals, investors, entrepreneurs, and
                celebrities who are looking for a passionate long-term
                relationship with an equally successful partner.
              </p>
            </div>
          </section>

          <section className="choose-lovrican">
            <div className="container-fluid">
              <h1 className="m-0" data-aos="zoom-in-down">
                What Sets Lovrican Apart
              </h1>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div
                    className={`chooseBox ${selected === 1 ? 'active' : ''}`}
                  >
                    <img data-aos="zoom-in-down" src={ChoiceImg} alt="" />
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      onClick={this.handleCancel}
                    >
                      &times;
                    </button>
                    <div
                      onClick={() => this.handleSelect(1)}
                      aria-hidden="true"
                    >
                      <h4 data-aos="zoom-in-down">
                        {/* Shared Culture & <br /> Global Reach */}
                        Exclusive <br />
                        Community of <br />
                        Successful
                        <br /> Nigerian Singles
                      </h4>
                      <div className="read-more">
                        <Scrollbars className="scroll-height">
                          <div>
                            <p className="expandText">
                              <span data-aos="zoom-in-down">
                                At Lovrican, we pride ourselves on curating an
                                exclusive community of successful Nigerian
                                singles in America. Join a pool of accomplished
                                individuals who understands the value of hard
                                work, ambition, and cultural heritage.
                              </span>
                              <br />
                              <br />
                              <span data-aos="zoom-in-down">
                                Our members comprise thriving entrepreneurs,
                                respected professionals, and influential
                                leaders. This creates an environment that
                                fosters connections with like-minded individuals
                                who appreciate your achievements and can inspire
                                you to reach new heights.
                              </span>
                            </p>
                          </div>
                        </Scrollbars>
                        <div className="fadeSection">
                          <div className="fadeInner">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className={`chooseBox ${selected === 2 ? 'active' : ''}`}
                  >
                    <img data-aos="zoom-in-down" src={Diverse} alt="" />
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      onClick={this.handleCancel}
                    >
                      &times;
                    </button>
                    <div
                      onClick={() => this.handleSelect(2)}
                      aria-hidden="true"
                    >
                      <h4 data-aos="zoom-in-down">
                        Tailored Matching <br />
                        Algorithm for <br />
                        Meaningful <br />
                        Connections
                      </h4>
                      <div className="read-more">
                        <Scrollbars className="scroll-height">
                          <div>
                            <p className="expandText">
                              <span data-aos="zoom-in-down">
                                Lovrican’s advanced matching algorithm takes
                                into account your preferences, interests, and
                                values to connect you with highly compatible
                                matches. We go beyond surface-level
                                compatibility and focus on deeper connections
                                that align with your vision for love and
                                success.
                              </span>
                              <br />
                              <br />
                              <span data-aos="zoom-in-down">
                                By utilizing our tailored matching algorithm,
                                you can expect to meet individuals who share
                                your aspirations, understand the demands of your
                                professional life, and appreciate your cultural
                                background.
                              </span>
                            </p>
                          </div>
                        </Scrollbars>
                        <div className="fadeSection">
                          <div className="fadeInner">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className={`chooseBox ${selected === 3 ? 'active' : ''}`}
                  >
                    <img data-aos="zoom-in-down" src={Efficient} alt="" />
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      onClick={this.handleCancel}
                    >
                      &times;
                    </button>
                    <div
                      onClick={() => this.handleSelect(3)}
                      aria-hidden="true"
                    >
                      <h4 data-aos="zoom-in-down">
                        High-Level <br />
                        Privacy and <br />
                        Security <br />
                        Measures
                      </h4>
                      <div className="read-more">
                        <Scrollbars className="scroll-height">
                          <div>
                            <p className="expandText">
                              <span data-aos="zoom-in-down">
                                Your privacy and security are of utmost
                                importance to us. Lovrican employs advanced
                                security measures to protect your personal
                                information, ensuring a safe and trustworthy
                                environment. With industry-leading encryption
                                protocols, you can feel confident knowing that
                                your data is secure and your online interactions
                                remain private.
                              </span>
                              <br />
                              <br />
                              {/* <span data-aos="zoom-in-down">
                      We know your time and resources are precious.
                      That is why our advanced screening system only
                      enrolls people who meet the high standards our
                      members expect. From that special pool of
                      high-quality members, it matches you with people
                      who meet your specific preferences. For you,
                      this means high-quality matches and dates with
                      fewer swipes, and you get to spend your time and
                      resources with people whose company you enjoy.
                      Lovrican is also easy to use, unlike other sites
                      that complicate matchmaking. You know what you
                      want, and Lovrican gives you a simple and
                      flexible way of specifying that.
                    </span> */}
                            </p>
                          </div>
                        </Scrollbars>
                        <div className="fadeSection">
                          <div className="fadeInner">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className={`chooseBox ${selected === 4 ? 'active' : ''}`}
                  >
                    <img data-aos="zoom-in-down" src={Cultured} alt="" />
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      onClick={this.handleCancel}
                    >
                      &times;
                    </button>
                    <div
                      onClick={() => this.handleSelect(4)}
                      aria-hidden="true"
                    >
                      <h4 data-aos="zoom-in-down">
                        Expert <br />
                        Screening and <br />
                        Approval <br />
                        Process
                      </h4>
                      <div className="read-more">
                        <Scrollbars className="scroll-height">
                          <div className="">
                            <p className="expandText">
                              <span data-aos="zoom-in-down">
                                Lovrican goes the extra mile to ensure that our
                                community is composed of successful and
                                professional individuals. Our expert screening
                                and approval process meticulously selects
                                members who align with our high standards and
                                aspirations.
                              </span>
                              <br />
                              <br />
                              <span data-aos="zoom-in-down">
                                By joining Lovrican, you can trust that
                                you&#39;ll be connecting with like-minded
                                individuals who share your commitment to
                                success, ambition, and cultural pride.
                              </span>
                            </p>
                          </div>
                        </Scrollbars>
                        <div className="fadeSection">
                          <div className="fadeInner">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className={`chooseBox ${selected === 5 ? 'active' : ''}`}
                  >
                    <img data-aos="zoom-in-down" src={Transparent} alt="" />
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      onClick={this.handleCancel}
                    >
                      &times;
                    </button>
                    <div
                      onClick={() => this.handleSelect(5)}
                      aria-hidden="true"
                    >
                      <h4 data-aos="zoom-in-down">
                        Focused on <br />
                        Cultural <br />
                        Connection <br />
                        and Heritage
                      </h4>
                      <div className="read-more">
                        <Scrollbars className="scroll-height">
                          <div className="">
                            <p className="expandText">
                              <span data-aos="zoom-in-down">
                                Lovrican celebrates the richness of Nigerian
                                culture and heritage. We provide a platform
                                where you can embrace your roots, connect with
                                individuals who appreciate your cultural
                                background, and share in the traditions that
                                make you who you are.
                              </span>
                              <br />
                              <br />
                              <span data-aos="zoom-in-down">
                                Whether you&#39;re looking for someone from your
                                own Nigerian roots or seeking to explore the
                                beauty of other Nigerian cultures around the
                                world, Lovrican offers a diverse pool of
                                individuals who understands and values your
                                heritage.
                              </span>
                            </p>
                          </div>
                        </Scrollbars>
                        <div className="fadeSection">
                          <div className="fadeInner">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div
                    className={`chooseBox ${selected === 6 ? 'active' : ''}`}
                  >
                    <img data-aos="zoom-in-down" src={Empowered} alt="" />
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      onClick={this.handleCancel}
                    >
                      &times;
                    </button>
                    <div
                      onClick={() => this.handleSelect(6)}
                      aria-hidden="true"
                    >
                      <h4 data-aos="zoom-in-down">
                        User-Friendly <br />
                        Interface and <br />
                        Convenient <br />
                        Features
                      </h4>
                      <div className="read-more">
                        <Scrollbars className="scroll-height">
                          <div className="">
                            <p className="expandText">
                              <span data-aos="zoom-in-down">
                                Lovrican is designed with your convenience in
                                mind. Our user-friendly interface and intuitive
                                features make it easy for you to navigate the
                                platform and discover unique potential matches
                                who are ideal for you.
                              </span>
                              <br />
                              <br />
                              <span data-aos="zoom-in-down">
                                With advanced search filters, personalized
                                matchmaking suggestions, and a mobile responsive
                                app-like interface for on-the-go connectivity,
                                Lovrican ensures that your dating experience is
                                seamless and enjoyable.
                              </span>
                            </p>
                          </div>
                        </Scrollbars>
                        <div className="fadeSection">
                          <div className="fadeInner">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row d-block d-md-none d-lg-none mobile-facing">
      <div className="col-md-12 text-left position-relative">
        <img
          data-aos="zoom-in-down"
          className="left-img"
          src={mobile}
          alt=""
        />
      </div>
    </div> */}
            </div>
          </section>
          <section className="choose-lovrican-face text-light">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-5 text-left position-relative d-none">
                  <img
                    data-aos="zoom-in-down"
                    className="left-img"
                    src={mobile}
                    alt=""
                  />
                  {/* <img  src={howWork} alt="" /> */}
                </div>
                <div className="col-md-7">
                  <div className="right">
                    <h1 data-aos="zoom-in-down">
                      Our
                      <br />
                      Values
                    </h1>
                    <div className="chooseBox pt-4">
                      <h4 data-aos="zoom-in-down">
                        <img className="mr-2" src={Excellence} alt="" />
                        Excellence
                      </h4>
                      <div className="read-more">
                        <div className="fixContant">
                          <p data-aos="zoom-in-down">
                            <span>
                              We engage fully in whatever we do and deliver
                              superior results through our ideas and actions.
                              Our roots inspire our constant innovation,
                              agility, and diligence. We take risks to shape the
                              future.
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="chooseBox pt-4">
                      <h4 data-aos="zoom-in-down">
                        <img className="mr-2" src={community} alt="" />
                        Community
                      </h4>
                      <div className="read-more">
                        <div className="fixContant">
                          <p data-aos="zoom-in-down">
                            In the spirit of African communitarianism, we
                            believe in “I am because we are.” We see the
                            thriving family unit as foundational to any great
                            society, and we cherish hospitality, love, and
                            harmony as the glue that makes us one out of many.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="chooseBox pt-4">
                      <h4 data-aos="zoom-in-down">
                        <img className="mr-2" src={Empathy} alt="" />
                        Empathy
                      </h4>
                      <div className="read-more">
                        <div className="fixContant">
                          <p data-aos="zoom-in-down">
                            We’re humans first. Thus, we highly value the
                            perspectives of others, listen with an open mind,
                            and speak with care. Our compassion and kindness for
                            humanity are fueled by our shared experiences.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="who_are_you_section banner-bg">
            <div className="container-fluid">
              <div className="love-text text-light text-left">
                <div className="row">
                  <div className="col-md-12 col-lg-5 col-12">
                    <h1 data-aos="zoom-in-down">Who We Are</h1>
                    <div
                      className={`readmoreBox ${selected === 7 ? 'active' : ''
                        }`}
                    >
                      <button
                        type="button"
                        className="close d-none"
                        data-dismiss="alert"
                        onClick={this.handleCancel}
                      >
                        ×
                      </button>
                      <div
                        onClick={() => this.handleSelect(7)}
                        aria-hidden="true"
                        style={{ cursor: 'pointer' }}
                      >
                        <h5 data-aos="zoom-in-down">Cultivated & Honest</h5>
                        <Scrollbars className="scroll-height">
                          <div className="" data-aos="zoom-in-down">
                            <p className="expentText">
                              Our platform is built on the foundation of
                              authenticity, candor, and embracing our cultural
                              roots while staying modern. As a dating site for
                              Nigerians in America, we pride ourselves on being
                              cultivated and honest, without any snobbishness,
                              pretense, or deceit.
                            </p>
                          </div>
                        </Scrollbars>
                        <span
                          data-aos="zoom-in-down"
                          className="dot_wrapper mt-3"
                          aria-hidden="true"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="dot" />
                          <span className="dot" />
                          <span className="dot" />
                        </span>
                      </div>
                    </div>
                    <div
                      className={`readmoreBox ${selected === 8 ? 'active' : ''
                        }`}
                    >
                      <button
                        type="button"
                        className="close d-none"
                        data-dismiss="alert"
                        onClick={this.handleCancel}
                      >
                        ×
                      </button>
                      <div
                        onClick={() => this.handleSelect(8)}
                        aria-hidden="true"
                        style={{ cursor: 'pointer' }}
                      >
                        <h5 data-aos="zoom-in-down">
                          Professional & Personable
                        </h5>
                        <Scrollbars className="scroll-height">
                          <div className="" data-aos="zoom-in-down">
                            <p className="expentText">
                              At Lovrican, we combine professionalism with a
                              warm personal touch. Our approach is anything but
                              reserved. We embrace a vibrant, innovative, and
                              flexible dating experience, making it easier for
                              you to meet affluent Nigerian singles in America.
                            </p>
                          </div>
                        </Scrollbars>
                        <span
                          data-aos="zoom-in-down"
                          className="dot_wrapper mt-3"
                          aria-hidden="true"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="dot" />
                          <span className="dot" />
                          <span className="dot" />
                        </span>
                      </div>
                    </div>
                    <div
                      className={`readmoreBox ${selected === 9 ? 'active' : ''
                        }`}
                    >
                      <button
                        type="button"
                        className="close d-none"
                        data-dismiss="alert"
                        onClick={this.handleCancel}
                      >
                        ×
                      </button>
                      <div
                        onClick={() => this.handleSelect(9)}
                        aria-hidden="true"
                        style={{ cursor: 'pointer' }}
                      >
                        <h5 data-aos="zoom-in-down">Optimistic & Pragmatic</h5>
                        <Scrollbars className="scroll-height">
                          <div className="" data-aos="zoom-in-down">
                            <p className="expentText">
                              We believe in reaching for higher ideals while
                              remaining grounded, thoughtful, and responsible.
                              Our platform is not driven by political ideologies
                              or fundamentalist beliefs. We are not here to
                              pander or support cynicism and status quo habits
                              or attitudes. Our approach is not abstract or
                              impractical, because we understand the importance
                              of real-world connections, meaningful
                              interactions, and productive relationships.
                            </p>
                          </div>
                        </Scrollbars>
                        <span
                          data-aos="zoom-in-down"
                          className="dot_wrapper mt-3"
                          aria-hidden="true"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="dot" />
                          <span className="dot" />
                          <span className="dot" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="readytoMeet banner-bg">
            <div className="title love-text text-light">
              <h1 data-aos="zoom-in-down">
                Ready to meet special <br />
                Nigerian singles in America?
              </h1>
              <p data-aos="zoom-in-down" className="pt-4">
                If you’re ready to start your journey to meet highly desirable{' '}
                <br />
                Nigerian singles in America, Lovrican is here to help you make{' '}
                <br />
                that connection. Whether seeking a partner who understands the{' '}
                <br />
                richness of your Nigerian roots or looking to embrace the <br />
                diversity of Nigerian cultures around the world, Lovrican offers{' '}
                <br />a curated community of like-minded individuals ready to
                connect, <br />
                discover common interests and values, and build meaningful{' '}
                <br />
                long-term relationships.
              </p>
              <h4 data-aos="zoom-in-down">
                Open the door to a world of possibilities
                <br /> in finding lasting love and companionship!
              </h4>
              <div className="join d-block mb-4 mb-sm-0">
                <button
                  data-aos="zoom-in-down"
                  className="btn theme-btn-purple btn"
                  type="button"
                  onClick={() => {
                    history.push('/register');
                  }}
                >
                  Join Lovrican Today
                </button>
              </div>
            </div>
          </section>

          <section className="love-arpiration text-center connect-people d-none">
            <div className="container">
              <div className="common-caption">
                <p className="pb-0">Coming Soon</p>
                <h1 className="text-light pb-2">Connecting People</h1>
                <div className="d-flex flex-column flex-md-row justify-content-center">
                  <img src={AppleStore} alt="" />
                  <img src={playStore} alt="" />
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className='main_home k_none'>
          <section className='banner_section banner_bg'>

            <div
              className="gift-home"
              aria-hidden="true"
              onClick={() => history.push(AppConstants.routes.login)}
            >
              Member Login
            </div>
            <HomeHeaderMobile />

            <div className='banner_inner'>
              <div className='container'>
                <h1>Cultivating Love. <br />
                  Elevating Success.</h1>
                <p>Select your Home Country to start meeting <br />
                  affuent African singles in America</p>
                <div className='btn_group'>
                  <Link className="btn" to="/"><img src={nigeriaFlag} alt="" /> Nigeria</Link>
                  <Link className="btn" to="/"><img src={kenyaFlag} alt="" /> Kenya</Link>
                </div>
              </div>
            </div>

          </section>
          <section className='membership'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-4 border_right text-center'>
                  <div className='membership_box'>
                    <h3>5</h3>
                    <p>minutes to start meeting <br />
                      exceptional African <br />
                      singles in America</p>
                  </div>
                </div>
                <div className='col-md-4 border_right text-center'>
                  <div className='membership_box'>
                    <h3>92%</h3>
                    <p>of our members are <br />
                      College Graduates*</p>
                  </div>
                </div>
                <div className='col-md-4 text-center'>
                  <div className='membership_box'>
                    <h3>Free</h3>
                    <p>to join Lovrican. <br />
                      Registration <br />
                      is quick and easy</p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 text-right'>
                  <p className='tag_line'>*Lovrican data as of July 15, 2024. Note: College degree not required for Lovrican membership</p>
                </div>
              </div>
            </div>
          </section>
          <section className='your_ideal scroll_overflow'>
            <div className='container'>
              <div className='row'>
              <div className='col-md-5'>
                <h4>Meet Successful African Singles in America to Find Your Ideal Soulmate Who Enriches You Personally and Professionally</h4>
              </div>
              <div className='col-md-7'>
              <img className='idelImg' src={meetsuccss} alt="" />
              <div className='right'>
                <h5>Lovrican is the premier online destination for affluent African singles in America</h5>
                <p>Many Africans crave meeting an equally successful partner who gets them culturally and professionally. At Lovrican, we believe that true compatibility is the foundation of every successful relationship. Our advanced matching algorithm takes into account your interests, values, and aspirations, ensuring that you are connected with like- minded individuals who share your vision for the future. </p>
<p>
Whether you’re looking for someone who shares your definition of family values, a partner who appreciates your cultural heritage or a companion who understands the demands of your professional life, Lovrican is committed to helping you find that special someone.</p>
              </div>
              </div>
              </div>
            </div>
          </section>

          <section className='lovrican_work scroll_overflow'>
             <div className='container'>
               <div className='row'>
                <div className='col-md-4'>
                  <h4>How <br/>
Lovrican <br/>
Works:</h4>
                </div>
                <div className='col-md-8'>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>1</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                      <img src={step1} alt="" />
                      </div>
                      <div className='content'>
                        <h5>Express Your Interest and 
                        Submit Your Basic Profile.</h5>
                        <p>Ready to embark on your journey to find love, success, and cultural connection? Joining Lovrican is as simple as expressing your interest and submitting your basic profile.</p>
                      </div>
                    </div>
                  </div>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>2</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                      <img src={step2} alt="" />
                      </div>
                      <div className='content'>
                        <h5>Expert Screening and
                        Approval.</h5>
                        <p>At Lovrican, we take great pride in our exclusive community. We review profiles with care and consideration, approving those that align with our high standards of success, affluence, and shared values.</p>
                      </div>
                    </div>
                  </div>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>3</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                      <img src={step3} alt="" />
                      </div>
                      <div className='content'>
                        <h5>Explore Curated and
                        Pre-Screened Matches.</h5>
                        <p>In your match queue, you’ll find a selection of exceptional individuals who have captured our attention, matching your preferences and aspirations.</p>
                      </div>
                    </div>
                  </div>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>4</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                      <img src={step4} alt="" />
                      </div>
                      <div className='content'>
                        <h5>Connect with Highly
                        Compatible Matches.</h5>
                        <p>Based on your unique profile, we’ll connect you with professional African singles in America who align with your vision for love and success.</p>
                      </div>
                    </div>
                  </div>
                  <div className='work_box'>
                    <div className='num'>
                      <div className='circle'>5</div>
                    </div>
                    <div className='contentWrap'>
                      <div className='fig'>
                      <img src={step5} alt="" />
                      </div>
                      <div className='content'>
                        <h5>EDiscover Your Special
                        African Soulmate.</h5>
                        <p>Prepare yourself for an incredible journey as Lovrican guides you toward discovering your special African soulmate.</p>
                      </div>
                    </div>
                  </div>
                </div>
               </div>
             </div>
          </section>

          <section className='marqueeContainer'>
            <div className='marquee-container'>
          <div className='marquee-text'>  Open the door to a world of possibilities in finding lasting love and companionship!</div>
            </div>
          </section>

          <section className='ready_to_meet'>
            <div className='banner_inner'>
            <div className='container'>
              <h5>Ready to meet special <br/>
              African singles in America?</h5>

</div>
            </div>
            
          </section>

          <section className='select_city'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 select_city_img'>
                <img src={africanLogo} alt="" />
                </div>
                <div className='col-md-6'>
                   <p>Select your Home Country to start meeting <br/>
                   affluent African singles in America:</p>
                   <div className='btn_group'>
                   <Link className="btn" to="/"><img src={nigeriaFlag} alt="" /> Nigeria</Link>
                   <Link className="btn" to="/"><img src={kenyaFlag} alt="" /> Kenya</Link>
                </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          limit={3}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <HowLovricanWorkPopup
          modalStatus={howLovricanWorkMsg}
          handleClose={this.handleCloseHowLovricanWorkMsg}
        />

        {confirmEmailMsg && (
          <ConfirmEmailPopup
            modalStatus={confirmEmailMsg}
            handleConfirm={this.submitForm}
          />
        )}
      </>
    );
  }
}

// const mapStateToProps = (state) => ({ ...state });

// const mapDispatchToProps = (dispatch) => ({
//   //loginPopupActionLocal: (payload) => dispatch(loginPopupAction(payload))
// });

// export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
export default HomeComponent;
