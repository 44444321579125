import React, { useState } from 'react';
import rose from '../../assets/images/rose.svg';
import filter from '../../assets/images/filter.svg';
import heart2 from '../../assets/images/hear3.svg';
import heart from '../../assets/images/hear2.svg';
import closeImg from '../../assets/images/close.svg';
import alreadylike from '../../assets/images/alreadyLike.png';
import ImageSlider2 from './imageSlider2';
import verfied from '../../assets/images/verified.svg';
import notVerfied from '../../assets/images/verified_Grey.svg';
import ageIcon from '../../assets/images/Cake.svg';
import VerifiedNotificationPopup from '../../pages/verifiedNotificationPopup';
import womanPrivate from '../../assets/images/woman-blur-match-queue.webp';
import manPrivate from '../../assets/images/man-blur-match-queue.webp';

const ProfileBox = ({
  current,
  data,
  handleDislike,
  handleLike,
  handleSendRose,
  filterParentCallBack
}) => {
  const [verifyMsg, setVerifyMsg] = useState(false);
  const handleCloseVerifyMsg = () => setVerifyMsg(false);

  const {
    // userId,
    images,
    userName,
    userAge,
    // dob,
    about,
    hobbies,
    // qualities,
    occupation,
    address,
    isSentRose,
    isLikeMe,
    rosesSentToMe,
    uDetails,
    status,
    phone,
    isProfilePrivate,
    privateAddress,
    height
  } = data;

  const { origin1, origin2, origin3 } = uDetails;

  const handleClickFilter = () => {
    filterParentCallBack(true);
  };

  const convertHeightToFeetAndInches = (decimalHeight) => {
    const feet = Math.floor(decimalHeight);
    const inches = Math.round((decimalHeight - feet) * 12);
    return `${feet}’ ${inches}”`;
  };

  return (
    <>
      <div className="d-flex justify-content-center new_matches_design">
        <div className="inerSection">
          <div className="image-slider-box d-inline-block">
            {isLikeMe && (
              <div className="alredy-likeu">
                <img src={alreadylike} alt="/" />
                <div>{userName.charAt(0).toUpperCase()}. already likes you</div>
              </div>
            )}
            <ImageSlider2
              images={images && images.length ? images : ['no_Image.svg']}
              isSentRose={isSentRose}
              rosesSentToMe={rosesSentToMe}
              isProfilePrivate={isProfilePrivate}
              privateImage={
                current.gender === 'MAN' ? womanPrivate : manPrivate
              }
            />
            <div className={`match-action-btns ${data.isSecondAttempt === 1 ? 'match-action-btns-second-chance' : ''}`}>
              <div
                onClick={handleDislike}
                onKeyDown={(event) => event.key === 'Enter' && handleDislike}
                role="button"
                tabIndex="0"
                className="btn1 smallBtn"
              >
                <img src={closeImg} alt="/" />
              </div>
              <div
                className="rose-box btn1"
                onClick={handleSendRose}
                onKeyDown={(event) => event.key === 'Enter' && handleSendRose}
                role="button"
                tabIndex="0"
              >
                <img
                  src={rose}
                  className="img-rose-fluid"
                  alt="Lovrican rose"
                />
              </div>
              <div
                className="btn1 btn2 smallBtn position-relative"
                onClick={handleLike}
                onKeyDown={(event) => event.key === 'Enter' && handleLike}
                role="button"
                tabIndex="0"
              >
                <img className='heartImg' src={heart} alt="/" />
                <img className='heartImg yellowImg' src={heart2} alt="/" />
                { data.isSecondAttempt === 1 && (
                    <div className='secondChance'>2nd chance</div>
                ) }
              </div>
            </div>
          </div>
          <div className="user-detail-box d-inline-block v-top">
            <div className="usr-info-box">
              <div
                className="filters-line text-right"
                style={{ display: 'none' }}
                aria-hidden="true"
                onClick={handleClickFilter}
              >
                <span>
                  <img src={filter} alt="" />
                  Filter
                </span>
              </div>
              <h5>
                <span className="match_name" title={userName}>
                  {userName.charAt(0).toUpperCase()}
                </span>
                <span className="dotAge">&#8226;</span>
                {userAge}
                <img
                  src={
                    status === 'ACTIVE' && phone !== null ? verfied : notVerfied
                  }
                  className={`img-fluid verifid_img ${
                    current?.status !== 'ACTIVE' || current?.phone === null
                      ? 'cursor-pointer'
                      : ''
                  }`}
                  alt="arrow icon"
                  aria-hidden="true"
                  onClick={() =>
                    setVerifyMsg(
                      current?.status !== 'ACTIVE' ||
                        current?.phone === null ||
                        false
                    )
                  }
                />
              </h5>
              {occupation ? (
                <p className="occupation">
                  {occupation}{' '}
                  {uDetails?.jobTitle ? `(${uDetails?.jobTitle})` : ''}
                  {uDetails?.companyName ? ' at ' : ''}
                  {uDetails?.companyName}
                </p>
              ) : (
                <p className="occupation missting_section_match">
                  Occupation to be added
                </p>
              )}
              {isProfilePrivate === 'NO'
                ? address && <p className="prfl_city">{address}</p>
                : privateAddress && (
                    <p className="prfl_city">{privateAddress}</p>
                  )}

              <p className="prf_height age_icon">
                <img src={ageIcon} alt="/" /> {userAge}
              </p>

              {height && (
                <p className="prf_height">
                  {convertHeightToFeetAndInches(height)}
                </p>
              )}
            </div>

            {data?.uDetails?.educationLabel && (
              <div className="about-me-box">
                <p className="head">Education:</p>
                <p className="about-dec">
                {data?.uDetails?.educationLabel}
                {data?.uDetails?.whatStudy && ` (${data?.uDetails?.whatStudy})`}
                {data?.uDetails?.schoolName && ` from ${data?.uDetails?.schoolName}`}
                </p>
              </div>
            )}

            <div className="about-me-box">
              <p className="head">About Me:</p>
              {about ? (
                <p className="about-dec">{about}</p>
              ) : (
                <p className="about-dec missting_section_match">
                  Intro summary to be added
                </p>
              )}
            </div>

            {origin1 && origin2 && origin3 && (
              <div className="about-me-box">
                <p className="head">My Roots:</p>
                <p className="about-dec">
                  {JSON.parse(origin3).label}, {JSON.parse(origin2).label},{' '}
                  {JSON.parse(origin1).label}
                </p>
              </div>
            )}

            {data?.uDetails?.lookingFor && (
              <div className="about-me-box">
                <p className="head">Looking for:</p>
                <p className="about-dec">{data?.uDetails?.lookingFor}</p>
              </div>
            )}

            <div className="ul-li-box hobbies-wrapper">
              <p className="head pb-2">Hobbies:</p>
              {hobbies ? (
                <ul className="color-list">
                  {hobbies.map((prop) => (
                    <li key={prop.label}>{prop.label}</li>
                  ))}
                </ul>
              ) : (
                <ul className="color-list missting_section_match">
                  <li>Hobbies to be added</li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <VerifiedNotificationPopup
        modalStatus={verifyMsg}
        handleClose={handleCloseVerifyMsg}
        userDetails={current}
      />
    </>
  );
};

export default ProfileBox;
