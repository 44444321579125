import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import csc from 'country-state-city';
import Select from 'react-dropdown-select';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import Resizer from 'react-image-file-resizer';
import OtpInput from 'react-otp-input';
import { setUser } from '../../redux/actions/user.actions';
import AppConstants from '../../appConstants';
import WdyhauList from '../../appConstants/wdyhau';
import SalaryRange from '../../appConstants/salaryRange';
import LoveGoals from '../../appConstants/loveGoals';
import privatelock from '../../assets/images/private_lock.svg';
import logo from '../../assets/images/Lovrican-Logo-Icon.svg';
import { authCall } from '../../services';
import DropInput from '../../components/general/dropInput';
import { isEmpty, isValidOtp } from '../../helpers/validator';
import CropperPopup from '../cropperPopup';
import RelationshipPopup from '../registerSections/RelationshipPopup';
// import ProfileCompletePopup from '../ProfileCompletePopup';
import NoticeComponent from './NoticeComponent';

const RELEATIONSHIP = [
  {
    value: 1,
    label: 'Single'
  },
  {
    value: 2,
    label: 'Married'
  },
  {
    value: 3,
    label: 'Divorced'
  }
];

const FEETS = [
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 }
];

const INCHES = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 }
];

const UpdateProfile = (props) => {
  const [showFirstComponent, setShowFirstComponent] = useState(true); // State to toggle between components
  
  const handleToggle = () => {
    setShowFirstComponent(prevValue => !prevValue); // Toggle state on button click
  };

  return (
    <div>
        {showFirstComponent ? (
            <NoticeComponent onButtonClickHandler={handleToggle} />
        ) : (
            <UpdateProfileFormComponent {...props} />
        )}
    </div>
  );
}

const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue(() => value + 1);
}

const UpdateProfileFormComponent = ({ userDetails, masterData }) => {
  const otpLength = 4;
  const history = useHistory();
  const dispatch = useDispatch();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [countriesOrigin, setCountriesOrigin] = useState([]);
  const [statesOrigin, setStatesOrigin] = useState([]);
  const [citiesOrigin, setCitiesOrigin] = useState([]);

  const forceUpdate = useForceUpdate();
  
  const [intPart, decPart] = userDetails?.height
    ? userDetails?.height.split('.')
    : useState(0);

  const [values, setValues] = useState({
    email: userDetails?.email,
    country: userDetails?.countryValue
      ? [{ value: userDetails?.countryValue, label: userDetails?.countryLabel }]
      : [],
    state:
      userDetails?.stateValue && userDetails?.stateLabel
        ? [
            {
              value: userDetails.stateValue,
              label: userDetails.stateLabel
            }
          ]
        : [],
    city:
      userDetails?.cityValue && userDetails?.cityLabel
        ? [
            {
              value: userDetails.cityValue,
              label: userDetails.cityLabel
            }
          ]
        : [],

    otp: '',
    isError: false,
    loveGoal: userDetails?.loveGoal ? userDetails?.loveGoal : [],
    relationship: userDetails?.relationship ? [userDetails?.relationship] : [],
    education: userDetails?.userDetails?.educations
      ? [userDetails?.userDetails?.educations]
      : [],
    anualIncome: userDetails?.anualIncome ? [userDetails?.anualIncome] : [],
    linkedInURL: userDetails?.linkedin_URL ? userDetails?.linkedin_URL : '',
    occupation: userDetails?.occupations ? [userDetails?.occupations] : [],
    careerLevel: userDetails?.careerLevelObj
      ? [userDetails?.careerLevelObj]
      : [],
    wdyhau: userDetails?.hearAbout ? [userDetails?.hearAbout] : [],
    profilePicture: userDetails?.profile_image || '',
    request: true,
    fileError: '',
    imageSizeValidation: false,
    imageSRC: null,
    show: false,
    imagePreview: userDetails?.profile_image
      ? process.env.REACT_APP_PIC_BASE_URL + userDetails?.profile_image
      : null,
    otpHelp: false,
    resendCode: false,
    otpAttamp: 0,
    otpVerified: userDetails?.is_otp_valid === '1' || false,
    relationshipStatus: false,
    isDisabledBtn: false,
    goalHelp: false,
    incomeHelp: false,
    linkedInHelp: false,
    careerHelp: false,
    hearAboutHelp: false,
    relationshipHelp: false,
    phoneNumber: userDetails?.phone ? userDetails?.phone : '',
    phoneHelp: false,
    originCityHelp: false,
    wdyhauHelp: false,
    whatStudy: userDetails?.userDetails?.whatStudy || '',
    schoolName: userDetails?.userDetails?.schoolName || '',
    jobTitle: userDetails?.userDetails?.jobTitle || '',
    companyName: userDetails?.userDetails?.companyName || '',
    heightFeet: userDetails?.height ? [{ label: intPart, value: intPart }] : [],
    heightInch: userDetails?.height ? [{ label: decPart, value: decPart }] : [],
    culturalGroup: userDetails?.userDetails?.ethnicGroupValue
      ? [
          {
            label: userDetails?.userDetails?.ethnicGroupLabel,
            value: userDetails?.userDetails?.ethnicGroupValue
          }
        ]
      : [],
    languages: userDetails?.userDetails?.languages
      ? [userDetails?.userDetails?.languages]
      : [],
    culturGroupHelp: false,
    subCultureGroupHelp: false,
    aboutMe: userDetails?.about ? userDetails?.about : '',
    countryOrigin: userDetails?.userDetails?.origin1
      ? [userDetails?.userDetails?.origin1]
      : [],
    stateOrigin: userDetails?.userDetails?.origin2
      ? [userDetails?.userDetails?.origin2]
      : [],
    cityOrigin: userDetails?.userDetails?.origin3
      ? [userDetails?.userDetails?.origin3]
      : [],
    lookingFor: userDetails?.userDetails?.lookingFor || '',
    hobbies: userDetails?.hobbies ? userDetails?.hobbies : [],
    profileCompleteStatus: true,
    selectedCountry: JSON.parse(localStorage.getItem('selectedCountry'))
  });

  const [validator] = useState(
    new SimpleReactValidator({
      validators: {
        custom_file: {
          message: values.fileError || 'The profile picture is required.',
          rule: () => values.fileError,
          required: true
        },
        linkedInURL: {
          message: 'Invalid linkedIn URL.',
          rule: (val) => {
            const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9-]+\/?$/;
            return linkedInRegex.test(val);
          },
          required: true
        },
        max_five: {
          message: 'Maximum of 5 values allowed.',
          rule: (val) => val.length < 6,
          required: true
        },
        phoneNumber: {
          message: 'Invalid mobile number.',
          rule: (val) => val.length < 12,
          required: true
        }
      }
    })
  );

  const resendCode = async () => {
    const { email } = values;
    if (isEmpty(email)) {
      toast.error(
        "We're not getting any Email to send the code, please try again from starting."
      );
    } else {
      const resp = await authCall('auth/action/resend-otp-to-email', {
        email
      });

      if (resp && resp.success === true) {
        setValues({
          ...values,
          resendCode: true
        });
      }

      setTimeout(() => {
        setValues({
          ...values,
          resendCode: false
        });
      }, 5000);
    }
  };

  const otpValidateForm = async () => {
    const { otp } = values;
    let ret = false;
    if (isValidOtp(otp)) {
      setValues({ ...values, isError: false });
      ret = true;
    } else {
      setValues({ ...values, isError: true });
    }
    return ret;
  };

  const otpsubmitForm = async (otp) => {
    const { email } = values;
    if (otpValidateForm()) {
      const resp = await authCall('auth/action/verify-otp-to-email', {
        email,
        otp
      });

      if (resp && resp.success === true) {
        setValues({ ...values, otpVerified: true, resendCode: false, otp: '' });
      } else {
        setValues({ ...values, isError: true, otp: '' });
      }
    }
  };

  const otpChange = (otpInner) => {
    setValues({ ...values, otp: otpInner });
    if (otpInner.length === otpLength) {
      otpsubmitForm(otpInner);
    }

    setTimeout(() => {
      Array.from(document.querySelectorAll('.inputStyleNew')).map((ele) => {
        if (ele.value) {
          ele.className = 'inputStyleNew focus-color'; // eslint-disable-line no-param-reassign
        }
        return ele.value;
      });
    }, 100);
  };

  const submitForm = async () => {
    const {
      country,
      state,
      city,
      loveGoal,
      education,
      relationship,
      anualIncome,
      careerLevel,
      request,
      occupation,
      wdyhau,
      profilePicture,
      linkedInURL,
      otpVerified,
      phoneNumber,
      whatStudy,
      schoolName,
      jobTitle,
      companyName,
      heightFeet,
      heightInch,
      culturalGroup,
      languages,
      aboutMe,
      countryOrigin,
      stateOrigin,
      cityOrigin,
      lookingFor,
      hobbies
    } = values;

    if (validator.allValid() && request) {
      if (!otpVerified) {
        return;
      }

      setValues({ ...values, request: false, imageSizeValidation: false });
      const formData = new FormData();
      formData.append('country', JSON.stringify(country));
      formData.append('state', JSON.stringify(state[0]));
      formData.append('city', JSON.stringify(city[0]));

      formData.append('loveGoal', JSON.stringify(loveGoal));
      formData.append('education', JSON.stringify(education));
      formData.append('annualIncome', JSON.stringify(anualIncome));
      formData.append('careerLevel', JSON.stringify(careerLevel));
      formData.append('occupation', JSON.stringify(occupation));
      formData.append('relationship', JSON.stringify(relationship));
      formData.append('wdyhau', JSON.stringify(wdyhau));
      formData.append('picture', profilePicture);
      formData.append('linkedin_URL', linkedInURL);
      formData.append('phone', phoneNumber);
      formData.append('whatStudy', whatStudy);
      formData.append('schoolName', schoolName);
      formData.append('jobTitle', jobTitle);
      formData.append('companyName', companyName);
      formData.append(
        'height',
        `${heightFeet[0].value}.${heightInch[0].value}`
      );

      formData.append('ethnic', JSON.stringify(culturalGroup[0]));
      formData.append('languages', JSON.stringify(languages[0]));
      formData.append('aboutMe', aboutMe);

      formData.append('countryOriginNew', JSON.stringify(countryOrigin[0]));
      formData.append('stateOriginNew', JSON.stringify(stateOrigin[0]));
      formData.append('cityOriginNew', JSON.stringify(cityOrigin[0]));
      formData.append('lookingFor', lookingFor);
      formData.append('hobbies', JSON.stringify(hobbies));
      formData.append('hobbies1', JSON.stringify(hobbies));
      formData.append('isProfileComplted', 'YES');

      const resp = await authCall('auth/register-section', formData);
      if (resp && resp.success === true) {
        dispatch(setUser(resp.data.user));
        history.push(AppConstants.routes.afterLogin);
      }

      setValues({ ...values, request: true });
    } else {
      window.scrollTo(0, 0);
      setValues({ ...values });
      validator.showMessages();
      forceUpdate();
    }
  };

  const handleFileChange = (files) => {
    const [firstFile] = files;
    if (!firstFile) {
      return;
    }

    Resizer.imageFileResizer(
      firstFile, // the file from input
      700, // width
      750, // height
      'WEBP', // compress format WEBP, JPEG, PNG
      90, // quality
      0, // rotation
      async (uri) => {
        setValues({ ...values, imageSRC: uri, show: true });
      },
      'base64' // blob or base64 default base64
    );
  };

  const changeImage = () => setValues({ ...values, imagePreview: null });

  const close = (val) => setValues({ ...values, show: val });

  const callbak = async (action, data) => {
    if (action === 'click') {
      setValues({
        ...values,
        profilePicture: data.blobFile,
        imageSizeValidation: false,
        imagePreview: data.uri,
        show: false
      });
    }
  };

  const handleClosePopup = () =>
    setValues({ ...values, relationshipStatus: false });

  const handleChangeRelationship = (value) => {
    if (value.label === 'Married') {
      setValues({
        ...values,
        relationship: value,
        relationshipStatus: true,
        isDisabledBtn: true
      });
    } else {
      setValues({ ...values, relationship: value, isDisabledBtn: false });
    }
  };

  const handleClickPrivateBtn = (name = '') => {
    setValues({
      ...values,
      linkedInHelp: false,
      phoneHelp: false,
      incomeHelp: false,
      goalHelp: false,
      careerHelp: false,
      hearAboutHelp: false,
      culturGroupHelp: false,
      subCultureGroupHelp: false,
      wdyhauHelp: false,
      originCityHelp: false,
      relationshipHelp: false,
      [name]: true
    });
  };

  const getCountriesOptions = async () => {
    const data = await csc.getAllCountries();
    const options = data.map((d) => ({
      value: d.isoCode,
      label: `${d.flag} ${d.name} (${d.isoCode})`
    }));
    setCountries(options);
    setCountriesOrigin(options);
  };

  const handelCountryChange = async (value, type) => {
    if (value[0]) {
      const data = await csc.getStatesOfCountry(value[0].value);
      const options = data.map((d) => ({
        value: d.isoCode,
        label: d.name
      }));

      if (type === 'origin') {
        setStatesOrigin(options);
        setCitiesOrigin([]);
        setValues({
          ...values,
          countryOrigin: value,
          stateOrigin: [],
          cityOrigin: []
        });
      } else {
        setStates(options);
        setCities([]);
        setValues({
          ...values,
          country: value,
          state: [],
          city: []
        });
      }
    } else {
      if (type === 'origin') {
        setValues({
          ...values,
          countryOrigin: [],
          stateOrigin: [],
          cityOrigin: []
        });
      }
      if (type !== 'origin') {
        setValues({
          ...values,
          country: [],
          state: [],
          city: []
        });
      }
    }
  };

  const handelStateChange = async (value, type) => {
    if (value[0]) {
      const { country, countryOrigin } = values;
      const county =
        type === 'origin' ? countryOrigin[0].value : country[0].value;
      const data = await csc.getCitiesOfState(county, value[0].value);
      const options = data.map((d) => ({
        value: d.name,
        label: d.name
      }));
      if (type === 'origin') {
        setCitiesOrigin(options);
        setValues({
          ...values,
          stateOrigin: value,
          cityOrigin: []
        });
      } else {
        setCities(options);
        setValues({
          ...values,
          state: value,
          city: []
        });
      }
    } else {
      if (type === 'origin') {
        setCitiesOrigin([]);
        setValues({
          ...values,
          stateOrigin: [],
          cityOrigin: []
        });
      }
      if (type !== 'origin') {
        setValues({
          ...values,
          state: [],
          city: []
        });
        setCities([]);
      }
    }
  };

  const handelCityChange = async (value, type) => {
    if (value[0]) {
      if (type === 'origin') {
        setValues({
          ...values,
          cityOrigin: value
        });
      } else {
        setValues({ ...values, city: value });
      }
    } else {
      if (type === 'origin') {
        setValues({
          ...values,
          cityOrigin: []
        });
      }
      if (type !== 'origin') {
        setValues({
          ...values,
          city: []
        });
      }
    }
  };

  const handleGetStates = async (value) => {
    if (value[0]) {
      const data = await csc.getStatesOfCountry(value[0].value);
      const options = data.map((d) => ({
        value: d.isoCode,
        label: d.name
      }));
      setStatesOrigin(options);
    } else {
      setStatesOrigin([]);
    }
  };

  useEffect(async () => {
    const { selectedCountry } = values;
    if (selectedCountry) {
      const data = await csc.getStatesOfCountry(selectedCountry[0].value);
      const options = data.map((d) => ({
        value: d.isoCode,
        label: d.name
      }));
      setStates(options);
      setValues({
        ...values,
        country: selectedCountry,
        states: options,
        cities: []
      });
    }
    getCountriesOptions();
    handleGetStates(values.countryOrigin);
  }, []);

//   const handleCloseProfileStatus = () => {
//       setValues({ ...values, profileCompleteStatus: false });
//   }

  return (
    <>
      <section className="registerDetails section-one">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <div className="logo cursor-pointer" role="button" tabIndex="0">
                <img src={logo} className="img-fluid" alt="Lovrican Logo" />
              </div>
            </div>
          </div>

          <div className="row join-reg top-join">
            <div className="col-md-8 col-lg-9">
              <h1 className="pb-2 pb-lg-4">
                Essential <br/> Profile <br />
                {/* Section 1: Qualification */}
              </h1>
              {/* <ul className="basic_bullet">
                <li>
                  Make a positive first impression with potential partners.
                </li>
                <li>
                  Meet people who suit your preferences and your vision for the
                  future.
                </li>
                <li>Filter out individuals who are not genuine.</li>
                <li>
                  Confirm authenticity and enhance credibility in the Lovrican
                  Community.
                </li>
                <li>
                  Your privacy is our priority; rest assured, your information
                  is handled with care.
                </li>
              </ul> */}

              {/* otp  */}
              <div className="row w-100 settingOtp">
                {!values.otpVerified && (
                  <>
                    <div className="col-12 d-flex align-items-end">
                      <OtpInput
                        value={values.otp}
                        onChange={(otpInner) => otpChange(otpInner)}
                        containerStyle="userOtpBbox"
                        inputStyle="inputStyleNew"
                        shouldAutoFocus
                        className="inputStyleNew focus-color otpInput"
                        isInputNum
                        hasErrored={values.isError}
                        isDisabled={
                          values.otpAttamp > AppConstants.OTP_ATTEMP_LIMIT
                        }
                      />
                      <div className="position-relative">
                        <div
                          className="helpTrip"
                          aria-hidden="true"
                          onClick={() =>
                            setValues({ ...values, otpHelp: true })
                          }
                        >
                          <i className="fa fa-question" aria-hidden="true" />
                        </div>
                        {values.otpHelp && (
                          <div className="toolTipHelp d-flex">
                            <p>
                              Check your email for the One Time Passcode. Use
                              this code to verify you email
                            </p>
                            <div className="ml-3">
                              <i
                                className="fa fa-times cursor-pointer"
                                aria-hidden="true"
                                onClick={() =>
                                  setValues({ ...values, otpHelp: false })
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 notification_otp largeForm_otp">
                      <span>Enter the OTP we just sent to your email.</span>
                      {validator.message(
                        'otp',
                        values.imageSizeValidationotp,
                        'required'
                      )}
                      {values.otp && !values.otpVerified && (
                        <div className="srv-validation-message">
                          Invalid otp
                        </div>
                      )}
                    </div>

                    {!values.resendCode && (
                      <div className="col-12 resend-code-text largeForm_resend">
                        <p>
                          Didn’t get the code?{' '}
                          <span
                            onClick={resendCode}
                            onKeyDown={(event) =>
                              event.key === 'Enter' && resendCode()
                            }
                            role="button"
                            tabIndex="0"
                          >
                            Resend code
                          </span>
                        </p>
                      </div>
                    )}
                  </>
                )}
                {values.resendCode && (
                  <div className="col-12 resend-code-text largepage">
                    <p className="msg">
                      <span> Code has been sent to your registered Email.</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 col-lg-3 text-center mt-3">
              <DropInput
                handleFileChange={handleFileChange}
                imagePreview={values.imagePreview}
                imageSizeValidation={false}
                changeImage={changeImage}
              />
              <div className="col-12 mt-3">
                {validator.message(
                  'profilePicture',
                  values.profilePicture,
                  'required'
                )}
              </div>
              <div className="col-12 mt-3">
                {values.imageSizeValidation && (
                  <div className="srv-validation-message">
                    Image size not more than 2 MB.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row join-reg bottom-join">
            {/* residence country */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3">
              <Select
                placeholder="Residence Country"
                options={countries}
                autoComplete="off"
                className={`custom_input ${!values.country ? 'field-color' : ''}`}
                values={values.country}
                onChange={(value) => handelCountryChange(value)}
              />
              {validator.message('state', values.country, 'required')}
            </div>

            {/* residence state */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3"> 
              <Select
                placeholder="Residence State"
                options={states}
                autoComplete="off"
                className={`custom_input ${values.state.length === 0 ? 'field-color' : ''}`}
                values={values.state}
                onChange={(value) => handelStateChange(value)}
              />
              {validator.message('state', values.state, 'required')}
            </div>

            {/* residence city */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3">
              <Select
                placeholder="Residence City"
                autoComplete="off"
                options={cities}
                className={`custom_input ${values.city.length === 0 ? 'field-color' : ''}`}
                values={values.city}
                onChange={(value) => handelCityChange(value)}
              />
              {validator.message('city', values.city, 'required')}
            </div>

            {/* relationship */}
            <div className="col-md-12 col-lg-3 pb-4 custome_select mb-3 private_wrapper">
              <Select
                placeholder="Relationship Status"
                options={RELEATIONSHIP}
                autoComplete="off"
                className={`custom_input ${values.relationship.length === 0 ? 'field-color' : ''}`}
                values={values.relationship}
                onChange={(value) => handleChangeRelationship(value[0])}
              />

              {validator.message(
                'relationship',
                values.relationship,
                'required'
              )}
              <div
                className="private_btn"
                aria-hidden="true"
                onClick={() => {
                  handleClickPrivateBtn('relationshipHelp');
                }}
              >
                Private
                <img
                  src={privatelock}
                  className="lock_private"
                  alt="Lock"
                  aria-hidden="true"
                />
              </div>
              {values.relationshipHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClickPrivateBtn()}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* hightest education label */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3">
              <Select
                placeholder="Highest Level of Education"
                options={masterData?.degree}
                autoComplete="off"
                className={`custom_input ${values.education.length === 0 ? 'field-color' : ''}`}
                values={values.education}
                onChange={(value) =>
                  setValues({ ...values, education: value[0] })
                }
              />
              {validator.message('education', values.education, 'required')}
            </div>

            {/* what did you study */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3">
              <input
                type="text"
                name="whatStudy"
                id="whatStudy"
                placeholder="What did you study (your major)"
                className={`custom_input ${!values.whatStudy ? 'field-color' : ''}`}
                autoComplete="off"
                value={values.whatStudy}
                onChange={(e) =>
                  setValues({ ...values, whatStudy: e.target.value })
                }
              />
              {validator.message('whatStudy', values.whatStudy, 'required')}
            </div>

            {/* school name */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3">
              <input
                type="text"
                name="schoolName"
                id="schoolName"
                placeholder="School name (for highest education level)"
                className={`custom_input ${!values.schoolName ? 'field-color' : ''}`}
                autoComplete="off"
                value={values.schoolName}
                onChange={(e) =>
                  setValues({ ...values, schoolName: e.target.value })
                }
              />
              {validator.message('schoolName', values.schoolName, 'required')}
            </div>

            {/* occupation */}
            <div className="col-md-6 col-lg-3 pb-4 custome_select mb-3">
              <Select
                placeholder="Occupation"
                options={masterData?.occupations}
                autoComplete="off"
                className={`custom_input ${values.occupation.length === 0 ? 'field-color' : ''}`}
                values={values.occupation}
                onChange={(value) =>
                  setValues({ ...values, occupation: value[0] })
                }
              />
              {validator.message('occupation', values.occupation, 'required')}
            </div>

            {/* job title */}
            <div className="col-md-6 col-lg-3 pb-4 custome_select mb-3">
              <input
                type="text"
                name="jobTitle"
                id="jobTitle"
                placeholder="Job title"
                className={`custom_input ${!values.jobTitle ? 'field-color' : ''}`}
                autoComplete="off"
                value={values.jobTitle}
                onChange={(e) =>
                  setValues({ ...values, jobTitle: e.target.value })
                }
              />
              {validator.message('jobTitle', values.jobTitle, 'required')}
            </div>

            {/* company name */}
            <div className="col-md-6 col-lg-3 pb-4 custome_select mb-3">
              <input
                type="text"
                name="company_name"
                id="company_name"
                placeholder="Company Name"
                className={`custom_input ${!values.companyName ? 'field-color' : ''}`}
                autoComplete="off"
                value={values.companyName}
                onChange={(e) =>
                  setValues({ ...values, companyName: e.target.value })
                }
              />
              {validator.message(
                'company_name',
                values.companyName,
                'required'
              )}
            </div>

            {/* annual income */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3 private_wrapper">
              <Select
                placeholder="Annual Income ($-K)"
                options={SalaryRange}
                autoComplete="off"
                className={`custom_input ${values.anualIncome.length === 0 ? 'field-color' : ''}`}
                values={values.anualIncome}
                onChange={(value) =>
                  setValues({ ...values, anualIncome: value[0] })
                }
              />

              {validator.message('anualIncome', values.anualIncome, 'required')}
              <div
                className="private_btn"
                aria-hidden="true"
                onClick={() => {
                  handleClickPrivateBtn('incomeHelp');
                }}
              >
                Private
                <img
                  src={privatelock}
                  className="lock_private"
                  alt="Lock"
                  aria-hidden="true"
                />
              </div>
              {values.incomeHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClickPrivateBtn()}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* height */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3">
              <div className="row large_page_feet ">
                <div className="col-md-4 col-lg-4 col-4 pt-3 custome_select mb-3">
                  <span className="long_reg"> Height</span>
                </div>
                <div className="col-md-4 col-lg-4 col-4 pb-4 custome_select mb-3">
                  <Select
                    placeholder="Feet"
                    options={FEETS}
                    autoComplete="off"
                    className={`custom_input feet_border ${values.heightFeet.length === 0 ? 'field-color' : ''}`}
                    values={values.heightFeet}
                    onChange={(value) =>
                      setValues({ ...values, heightFeet: value })
                    }
                  />
                  {/* <div className="feet_text">ft</div> */}
                  {validator.message(
                    'heightFeet',
                    values.heightFeet,
                    'required'
                  )}
                </div>
                <div className="col-md-4 col-lg-4 col-4 pb-4 custome_select mb-3">
                  <Select
                    placeholder="Inches"
                    options={INCHES}
                    autoComplete="off"
                    className={`custom_input feet_border ${values.heightInch.length === 0 ? 'field-color' : ''}`}
                    values={values.heightInch}
                    onChange={(value) =>
                      setValues({ ...values, heightInch: value })
                    }
                  />

                  {validator.message(
                    'heightInch',
                    values.heightInch,
                    'required'
                  )}
                  {/* <div className="feet_text">in”</div> */}
                </div>
              </div>
            </div>

            {/* cultural group */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3 private_wrapper">
              <Select
                placeholder="Cultural Group"
                options={masterData.cultrual}
                autoComplete="off"
                className={`custom_input ${values.culturalGroup.length === 0 ? 'field-color' : ''}`}
                values={values.culturalGroup}
                onChange={(value) =>
                  setValues({ ...values, culturalGroup: value })
                }
              />

              {validator.message(
                'culturalGroup',
                values.culturalGroup,
                'required'
              )}

              <div
                className="private_btn"
                aria-hidden="true"
                onClick={() => {
                  handleClickPrivateBtn('culturGroupHelp');
                }}
              >
                Private
                <img
                  src={privatelock}
                  className="lock_private"
                  alt="Lock"
                  aria-hidden="true"
                />
              </div>

              {values.culturGroupHelp && (
                <div className="toolTipHelp d-flex left">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClickPrivateBtn()}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* sub cultural group */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3 private_wrapper">
              <Select
                placeholder="Sub-culture (Ethnic Group/Tribe)"
                options={masterData.languages}
                className={`custom_input ${values.languages.length === 0 ? 'field-color' : ''}`}
                values={values.languages}
                onChange={(value) => setValues({ ...values, languages: value })}
              />
              {validator.message('Sub-culture', values.languages, 'required')}

              <div
                className="private_btn"
                aria-hidden="true"
                onClick={() => {
                  handleClickPrivateBtn('subCultureGroupHelp');
                }}
              >
                Private
                <img
                  src={privatelock}
                  className="lock_private"
                  alt="Lock"
                  aria-hidden="true"
                />
              </div>

              {values.subCultureGroupHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClickPrivateBtn()}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* about me */}
            <div className="col-md-12 col-lg-6 pb-2 custome_select mb-3">
              <textarea
                type="text"
                name="aboutMe"
                placeholder="About Me"
                className={`custom_input resize-none ${!values.aboutMe ? 'field-color' : ''}`}
                autoComplete="off"
                value={values.aboutMe}
                onChange={(e) =>
                  setValues({ ...values, aboutMe: e.target.value })
                }
              />
              {validator.message(
                'aboutMe',
                values.aboutMe,
                'required|min:50|max:300'
              )}
            </div>

            {/* origin */}
            <div className="col-md-12 col-lg-3 pt-3 custome_select mb-3">
              Your Origin:
            </div>

            {/* origin country */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3">
              <Select
                placeholder="Origin Country"
                options={countriesOrigin}
                autoComplete="off"
                className={`custom_input ${values.countryOrigin.length === 0 ? 'field-color' : ''}`}
                values={values.countryOrigin}
                onChange={(value) => handelCountryChange(value, 'origin')}
              />
              {validator.message('country', values.countryOrigin, 'required')}
            </div>

            {/* origin state */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3">
              <Select
                placeholder="Origin  State"
                options={statesOrigin}
                autoComplete="off"
                className={`custom_input ${values.stateOrigin.length === 0 ? 'field-color' : ''}`}
                values={values.stateOrigin}
                onChange={(value) => handelStateChange(value, 'origin')}
              />
              {validator.message('state', values.stateOrigin, 'required')}
            </div>

            {/* origin city */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3 private_wrapper">
              <Select
                placeholder="Origin City"
                autoComplete="off"
                options={citiesOrigin}
                className={`custom_input ${values.cityOrigin.length === 0 ? 'field-color' : ''}`}
                values={values.cityOrigin}
                onChange={(value) => handelCityChange(value, 'origin')}
              />
              {validator.message('city', values.cityOrigin, 'required')}
              <div
                className="private_btn"
                aria-hidden="true"
                onClick={() => {
                  handleClickPrivateBtn('originCityHelp');
                }}
              >
                Private
                <img
                  src={privatelock}
                  className="lock_private"
                  alt="Lock"
                  aria-hidden="true"
                />
              </div>

              {values.originCityHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        handleClickPrivateBtn();
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* looking for */}
            <div className="col-md-12 col-lg-6 pb-2 custome_select mb-3">
              <textarea
                type="text"
                name="lookingFor"
                placeholder="I am looking for..."
                className={`custom_input resize-none ${!values.lookingFor ? 'field-color' : ''}`}
                autoComplete="off"
                value={values.lookingFor}
                onChange={(e) =>
                  setValues({ ...values, lookingFor: e.target.value })
                }
              />
              {validator.message(
                'lookingFor',
                values.lookingFor,
                'required|min:50|max:300'
              )}
            </div>

            {/* love goal */}
            <div className="col-md-12 col-lg-3 pb-2 custome_select mb-3 private_wrapper">
              <Select
                multi
                limit={5}
                placeholder="Love Goals"
                options={LoveGoals}
                autoComplete="off"
                className={`custom_input ${values.loveGoal.length === 0 ? 'field-color' : ''}`}
                values={values.loveGoal}
                onChange={(value) => setValues({ ...values, loveGoal: value })}
              />

              {validator.message('loveGoal', values.loveGoal, 'required')}
              <div
                className="private_btn"
                aria-hidden="true"
                onClick={() => {
                  handleClickPrivateBtn('goalHelp');
                }}
              >
                Private
                <img
                  src={privatelock}
                  className="lock_private"
                  alt="Lock"
                  aria-hidden="true"
                />
              </div>
              {values.goalHelp && (
                <div className="toolTipHelp d-flex left">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClickPrivateBtn()}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* how did you hear about us */}
            <div className="col-md-12 col-lg-3 pb-4 custome_select mb-3 private_wrapper">
              <Select
                placeholder="How did you hear about us?"
                options={WdyhauList}
                className={`custom_input ${values.wdyhau.length === 0 ? 'field-color' : ''}`}
                values={values.wdyhau}
                onChange={(value) => setValues({ ...values, wdyhau: value[0] })}
              />
              {validator.message('wdyhau', values.wdyhau, 'required')}
              <div
                className="private_btn"
                aria-hidden="true"
                onClick={() => {
                  handleClickPrivateBtn('wdyhauHelp');
                }}
              >
                Private
                <img
                  src={privatelock}
                  className="lock_private"
                  alt="Lock"
                  aria-hidden="true"
                />
              </div>

              {values.wdyhauHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClickPrivateBtn()}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* hobbies */}
            <div className="col-md-12 col-lg-3 pb-4 custome_select mb-3">
              <Select
                placeholder="Hobbies"
                multi
                options={masterData?.hobbies}
                limit={5}
                autoComplete="off"
                className={`custom_input ${values.hobbies.length === 0 ? 'field-color' : ''}`}
                values={values.hobbies}
                onChange={(value) => setValues({ ...values, hobbies: value })}
              />
              {validator.message(
                'hobbies',
                values.hobbies,
                'required|max_five'
              )}
            </div>

            {/* phone number */}
            <div className="col-md-6 col-lg-3 pb-4 mb-3 private_wrapper">
              <div className={`custom_input p-0 ${!values.phoneNumber ? 'field-color' : ''}`}>
                <PhoneInput
                  containerClass={`custom_flag ${!values.phoneNumber ? 'field-color' : ''} field-color`}
                  placeholder="Phone Number"
                  country="us"
                  value={values.phoneNumber}
                  onChange={(phone) =>
                    setValues({ ...values, phoneNumber: phone })
                  }
                />
                <input
                  type="text"
                  name="user-phone_number"
                  id="user-phone_number"
                  placeholder="Phone Number"
                  autoComplete="off"
                  className={`custom_input d-none ${!values.phoneNumber ? 'field-color' : ''}`}
                  value={values.phoneNumber}
                  onChange={(e) =>
                    setValues({ ...values, phoneNumber: e.target.value })
                  }
                />
              </div>
              {validator.message(
                'Phone Number',
                values.phoneNumber,
                'phoneNumber|required'
              )}
              <div
                className="private_btn"
                aria-hidden="true"
                onClick={() => {
                  handleClickPrivateBtn('phoneHelp');
                }}
              >
                Private
                <img
                  src={privatelock}
                  className="lock_private"
                  alt="Lock"
                  aria-hidden="true"
                />
              </div>
              {values.phoneHelp && (
                <div className="toolTipHelp d-flex left">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClickPrivateBtn()}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* linkedin url */}
            <div className="col-md-6 col-lg-3 pb-4 mb-3 private_wrapper">
              <input
                type="text"
                name="user-linkedIn_URL"
                id="user-linkedIn_URL"
                placeholder="Your LinkedIn Profile Link"
                autoComplete="off"
                className={`custom_input ${!values.linkedInURL ? 'field-color' : ''}`}
                value={values.linkedInURL}
                onChange={(e) =>
                  setValues({ ...values, linkedInURL: e.target.value })
                }
              />
              {validator.message(
                'linkedIn url',
                values.linkedInURL,
                'linkedInURL|required'
              )}
              <div
                className="private_btn"
                aria-hidden="true"
                onClick={() => {
                  handleClickPrivateBtn('linkedInHelp');
                }}
              >
                Private
                <img
                  src={privatelock}
                  className="lock_private"
                  alt="Lock"
                  aria-hidden="true"
                />
              </div>
              {values.linkedInHelp && (
                <div className="toolTipHelp d-flex">
                  <p>
                    This information is private and never shared with anyone.
                    Lovrican uses this information to ensure all members meet
                    the high standards our members expect.
                  </p>
                  <div className="ml-3">
                    <i
                      className="fa fa-times cursor-pointer"
                      aria-hidden="true"
                      onClick={() => handleClickPrivateBtn()}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 col-lg-12 pb-4 mb-3 mt-3  marb7 text-right">
              {!values.isDisabledBtn && (
                <span
                  className={`new_next_btn ${
                    !values.request ? 'hks-btn-disable' : ''
                  }`}
                  onClick={submitForm}
                  onKeyDown={(event) => event.key === 'Enter' && submitForm()}
                  role="button"
                  tabIndex="0"
                >
                  Meet Matches
                </span>
              )}

              {values.isDisabledBtn && (
                <span
                  disabled
                  className={`new_next_btn ${
                    !values.request ? 'hks-btn-disable' : ''
                  }`}
                  onClick={() => {
                    setValues({ ...values, relationshipStatus: true });
                  }}
                  onKeyDown={(event) =>
                    event.key === 'Enter' &&
                    setValues({ ...values, relationshipStatus: true })
                  }
                  role="button"
                  tabIndex="0"
                >
                  Meet Matches
                </span>
              )}
            </div>
          </div>
        </div>
      </section>
      <CropperPopup
        modalStatus={values.show}
        handleClose={close}
        parentCallback={callbak}
        imageSRC={values.imageSRC}
      />
      <RelationshipPopup
        modalStatus={values.relationshipStatus}
        handleClose={handleClosePopup}
      />
      {/* <ProfileCompletePopup
        modalStatus={values.profileCompleteStatus}
        handleClose={handleCloseProfileStatus}
        userDetails={userDetails}
      /> */}
    </>
  );
};

export default UpdateProfile;
