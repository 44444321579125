import moment from 'moment';
// import { toast } from 'react-toastify';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../redux/actions/user.actions';
import { pageTitle } from '../redux/actions/home.actions';
import webServices from '../services/webServices';
import PasswordAndEmailSetting from '../components/account-status';
import AccountStatusSetting from '../components/account-status/accountStatusSetting';

const AccordionCustomToggle = ({ title, eventKey, parentCallBack, status }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    const accStatus = status === 'open' ? 'close' : 'open';
    parentCallBack(eventKey, accStatus);
  });
  return (
    <div
      className="card-link"
      onClick={decoratedOnClick}
      onKeyDown={(event) => event.key === 'Enter' && decoratedOnClick()}
      role="button"
      tabIndex="0"
    >
      <span>{title}</span>
      <span>
        {status === 'open' ? (
          <i className="fa fa-minus" />
        ) : (
          <i className="fa fa-plus" />
        )}
      </span>
    </div>
  );
};

const SettingsPage = () => {
  const dispatch = useDispatch();
  const [userSettings, setUserSettings] = useState(null);
  const [accStatus, setAccStatus] = useState({
    emailPasswordBox: 'open',
    subscriptionBox: 'close',
    accountStatusBox: 'close'
  });
  const { current: userData } = useSelector((state) => state.user);
  const [isCancelSubscriptionDisabled, setIsCancelSubscriptionDisabled] = useState(false);
  const isSubscriptionPurchased = useRef(false);

  useEffect(async () => {
    dispatch(pageTitle('Setting'));
    const response = await webServices.getUserSettings();
    if (response && response.success) {
      isSubscriptionPurchased.current = response.data.plan_subscription_id !== null;
      setIsCancelSubscriptionDisabled(response.data.plan_subscription_id === null);
      setUserSettings(response.data);
    }
  }, []);

  const parentCallBackWhenUpdate = async (val) => {
    if (val === 'updated') {
      const response = await webServices.getUserSettings();
      if (response && response.success) {
        setUserSettings(response.data);
      }
    }
  };

  useEffect(() => {
    webServices.getMasterData().then((response) => {
      if (response && response.success) {
        // setMasterData(response.data);
      }
    });
  }, []);

  const callBack = (key, toggleStatus) => {
    const init = { ...accStatus };
    init.emailPasswordBox = 'close';
    init.subscriptionBox = 'close';
    init.accountStatusBox = 'close';

    if (key === '0') {
      init.emailPasswordBox = toggleStatus;
    } else if (key === '1') {
      init.subscriptionBox = toggleStatus;
    } else if (key === '2') {
      init.accountStatusBox = toggleStatus;
    }

    setAccStatus(init);
  };

  const handleCancelSubscriptionClickEvent = async () => {
    // eslint-disable-next-line
    if (window.confirm('Are you sure want to cancel your subscription?')) {
      setIsCancelSubscriptionDisabled(true);
      const result = await webServices.cancelAuthorizeNetSubscription();
      if (result?.success === true) {
        isSubscriptionPurchased.current = false;
        dispatch(setUser({ ...userData, plan_subscription_id: null }));
      } else {
        setIsCancelSubscriptionDisabled(false);
      }
    }
  };

  const getPlanRemainingDays = () => {
    if ((userData?.plan_expiry_date ?? null) === null) return 0;

    const today = moment().startOf('day');
    const remainingDays = moment(userData.plan_expiry_date).startOf('day').diff(today, 'days');

    return remainingDays > 0 ? remainingDays : 0;
  }

  return (
    <div className="inner-wrapper-newdesign profile-wrapper-outer">
      <div className="largeScreen">
        <div className="row">
          <div className="col-md-12 profile-box profile-wrapper">
            <Accordion
              defaultActiveKey="0"
              className="profile-accordion new_profile_accordion mt-4 accordion"
            >

              <Card>
                <Card.Header className="pb-2">
                  <AccordionCustomToggle
                    eventKey="0"
                    title="Password and Email"
                    status={accStatus.emailPasswordBox}
                    parentCallBack={callBack}
                  />
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <PasswordAndEmailSetting
                      userSettings={userSettings}
                      parentCallBackWhenUpdate={parentCallBackWhenUpdate}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header className="pb-2">
                  <AccordionCustomToggle
                    eventKey="1"
                    title="Manage Subscription"
                    status={accStatus.subscriptionBox}
                    parentCallBack={callBack}
                  />
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className='cancil_sub_wrapper'>
                      <p className='ml-0 mr-0 w-100'>
                        <span>{getPlanRemainingDays()} DAYS </span>
                        remaining in your current plan
                      </p>
                      <button
                        className="btn cancil_sub"
                        type="button"
                        onClick={handleCancelSubscriptionClickEvent}
                        disabled={isCancelSubscriptionDisabled}
                      >
                        Cancel Subscription
                      </button>
                      {
                        isSubscriptionPurchased.current === true ? (
                          <div>
                            Note* You will continue to have full access to Lovrican features until the
                            expiration of current paid subscription that expires on {moment(userData.plan_expiry_date).format('DD MMM, YYYY')}
                          </div>
                        ) : (
                          <div>
                            Note* You will continue to have full access to Lovrican features until the
                            expiration of current paid subscription that expires on {moment(userData.plan_expiry_date).format('DD MMM, YYYY')}
                          </div>
                        )
                      }
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Card.Header className="pb-2">
                  <AccordionCustomToggle
                    eventKey="2"
                    title="Account Status"
                    status={accStatus.accountStatusBox}
                    parentCallBack={callBack}
                  />
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <AccountStatusSetting userSettings={userSettings} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
