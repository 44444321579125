import AppConstants from '../appConstants';
import {
  BillingDetails,
  Chat,
  CommunityStandards,
  ContactUs,
  EditProfile,
  EditProfilePhotos,
  Faq,
  Home,
  AboutUs,
  Matches,
  MatchQueue,
  Order,
  PageNotFound,
  Plans,
  RosePlans,
  LikesPlans,
  PrivacyPolicy,
  PublicProfile,
  Register,
  SafetyTips,
  ServiceTerms,
  Settings,
  PlansView,
  ViewProfile,
  ViewProfilePhotos,
  RegisterSuccess,
  Login,
  AdminLogin,
  // Password,
  AdminDashboard,
  ForgotPassword,
  Gift,
  RoseHistory,
  LikeHistory,
  GiftSuccess,
  GiftFail,
  GiftEdit,
  EmailVerification,
  Affiliate,
  AffiliateAccountType,
  AffiliateRegister,
  AffiliateRegisterSections,
  AffiliateLogin,
  PublicProfileAffiliate,
  SettingAffiliate,
  DashboardAffiliate,
  EarningsAffiliate,
  ReferalsAffiliate,
  ContactAffiliate,
  Congratulations,
  AffiliateRegStep3,
  AffiliateRegSuccess,
  AccountDeleteFeedbacks,
  RewardsFeedbacks,
  PayPalCheckout,
  PaymentSuccess,
  PaymentFail,
  RegisterSections
} from '../pages';

const indexRoutes = [
  {
    exact: true,
    path: AppConstants.routes.login,
    name: 'Login',
    component: Login
  },
  {
    exact: true,
    path: AppConstants.routes.forgotPassword,
    name: 'forgot password',
    component: ForgotPassword
  },
  {
    exact: true,
    path: AppConstants.routes.emailVerification,
    name: 'Email Verification',
    component: EmailVerification
  },
  {
    exact: true,
    path: AppConstants.routes.admin.login,
    name: 'Admin Login',
    component: AdminLogin
  },
  {
    exact: true,
    path: AppConstants.routes.admin.dashboard,
    name: 'Recent Profiles',
    component: AdminDashboard
  },
  {
    exact: true,
    path: AppConstants.routes.admin.premium_profiles,
    name: 'Premium Profiles',
    component: AdminDashboard
  },
  {
    exact: true,
    path: AppConstants.routes.admin.all_profiles,
    name: 'All Profiles',
    component: AdminDashboard
  },
  {
    exact: true,
    path: AppConstants.routes.admin.approved_profiles,
    name: 'Approved Profiles',
    component: AdminDashboard
  },
  {
    exact: true,
    path: AppConstants.routes.admin.suspended_profiles,
    name: 'Suspended Profiles',
    component: AdminDashboard
  },
  {
    exact: true,
    path: AppConstants.routes.admin.hidden_profiles,
    name: 'Hidden Profiles',
    component: AdminDashboard
  },
  {
    exact: true,
    path: AppConstants.routes.admin.rejected_profiles,
    name: 'Rejected Profiles',
    component: AdminDashboard
  },
  {
    exact: true,
    path: AppConstants.routes.admin.deleted_profiles,
    name: 'Deleted Profiles',
    component: AdminDashboard
  },
  {
    exact: true,
    path: AppConstants.routes.home,
    name: 'Home',
    component: Home
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.home,
    name: 'Affiliate',
    component: Affiliate
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.accountType,
    name: 'Affiliate Account Type',
    component: AffiliateAccountType
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.register,
    name: 'Affiliate Resigter',
    component: AffiliateRegister
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.registerSection,
    name: 'Affiliate Resigter Sections',
    component: AffiliateRegisterSections
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.login,
    name: 'Affiliate Login',
    component: AffiliateLogin
  },
  {
    exact: true,
    path: AppConstants.routes.affiliateSteps3,
    name: 'Affiliate Steps3',
    component: AffiliateRegStep3
  },
  {
    exact: true,
    path: AppConstants.routes.AffiliateRegSuccess,
    name: 'Affiliate Success',
    component: AffiliateRegSuccess
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.profile,
    name: 'Public Profile Affilate',
    component: PublicProfileAffiliate
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.settings,
    name: 'Setting Affilate',
    component: SettingAffiliate
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.dashboard,
    name: 'Dashboard Affilate',
    component: DashboardAffiliate
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.earnings,
    name: 'Earning Affilate',
    component: EarningsAffiliate
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.referals,
    name: 'Referals Affilate',
    component: ReferalsAffiliate
  },
  {
    exact: true,
    path: AppConstants.routes.affiliate.contactsUs,
    name: 'Contact Affilate',
    component: ContactAffiliate
  },
  {
    exact: true,
    path: AppConstants.routes.registerSuccess,
    name: 'Register Success',
    component: RegisterSuccess
  },
  {
    exact: true,
    path: AppConstants.routes.plans,
    name: 'Plans',
    component: Plans
  },
  {
    exact: true,
    path: AppConstants.routes.payPalCheckout,
    name: 'Pay Pal',
    component: PayPalCheckout
  },
  {
    exact: true,
    path: AppConstants.routes.paymentSuccess,
    name: 'Payment success',
    component: PaymentSuccess
  },
  {
    exact: true,
    path: AppConstants.routes.paymentFail,
    name: 'Pay Pal',
    component: PaymentFail
  },
  {
    exact: true,
    path: AppConstants.routes.likesPlans,
    name: 'Likes Plans',
    component: LikesPlans
  },
  {
    exact: true,
    path: AppConstants.routes.rosePlans,
    name: 'Rose Plans',
    component: RosePlans
  },
  {
    exact: false,
    path: AppConstants.routes.order,
    name: 'Order',
    component: Order
  },
  {
    exact: true,
    path: AppConstants.routes.register,
    name: 'Register',
    component: Register
  },
  {
    exact: true,
    path: AppConstants.routes.registerSections,
    name: 'Register Sections',
    component: RegisterSections
  },
  {
    exact: true,
    path: AppConstants.routes.congratulations,
    name: 'Congratulations Profile Complete',
    component: Congratulations
  },
  {
    exact: true,
    path: '/gift',
    name: 'Gift',
    component: Gift
  },
  {
    exact: true,
    path: '/giftEdit',
    name: 'Gift Edit',
    component: GiftEdit
  },
  {
    exact: true,
    path: '/gift/success',
    name: 'Gift Success',
    component: GiftSuccess
  },
  {
    exact: true,
    path: '/gift/fail',
    name: 'Gift Fail',
    component: GiftFail
  },
  {
    exact: true,
    path: AppConstants.routes.afterLogin,
    name: 'Matches',
    component: Matches
  },
  {
    exact: true,
    path: AppConstants.routes.publicProfile,
    name: 'Public Profile',
    component: PublicProfile
  },
  {
    exact: true,
    path: AppConstants.routes.editProfile,
    name: 'Edit Profile',
    component: EditProfile
  },
  {
    exact: true,
    path: AppConstants.routes.chat,
    name: 'Chat',
    component: Chat
  },
  {
    exact: true,
    path: AppConstants.routes.editProfilePhotos,
    name: 'Edit Profile photos',
    component: EditProfilePhotos
  },
  {
    exact: true,
    path: AppConstants.routes.viewProfile,
    name: 'View Profile',
    component: ViewProfile
  },
  {
    exact: true,
    path: AppConstants.routes.viewProfilePhotos,
    name: 'View Profile photos',
    component: ViewProfilePhotos
  },
  {
    exact: true,
    path: AppConstants.routes.settings,
    name: 'Settings',
    component: Settings
  },
  {
    exact: true,
    path: AppConstants.routes.plansView,
    name: 'PlansView',
    component: PlansView
  },
  {
    exact: true,
    path: AppConstants.routes.billingDetails,
    name: 'Billing Details',
    component: BillingDetails
  },
  {
    exact: true,
    path: AppConstants.routes.matchQueue,
    name: 'Match Queue',
    component: MatchQueue
  },
  {
    exact: true,
    path: AppConstants.routes.roseHistory,
    name: 'Rose History',
    component: RoseHistory
  },
  {
    exact: true,
    path: AppConstants.routes.likeHistory,
    name: 'Like History',
    component: LikeHistory
  },
  {
    exact: true,
    path: AppConstants.routes.feedbacks,
    name: 'Account delete feedbacks',
    component: AccountDeleteFeedbacks
  },
  {
    exact: true,
    path: AppConstants.routes.rewardFeedbacks,
    name: 'Rewards feedbacks',
    component: RewardsFeedbacks
  },
  {
    exact: true,
    path: AppConstants.routes.faq,
    name: 'Frequently Asked Questions',
    component: Faq
  },
  {
    exact: true,
    path: AppConstants.routes.contact,
    name: 'Contact Us',
    component: ContactUs
  },
  {
    exact: true,
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    exact: true,
    path: '/about-us',
    name: 'About Us',
    component: AboutUs
  },
  {
    exact: true,
    path: '/safety-tips',
    name: 'Safety Tips',
    component: SafetyTips
  },
  {
    exact: true,
    path: '/community-standards',
    name: 'Community Standards',
    component: CommunityStandards
  },
  {
    exact: true,
    path: '/terms-of-service',
    name: 'Terms of Service',
    component: ServiceTerms
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound
  }
];

export default indexRoutes;
